/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import TrendingImg from "@assets/icons/trending.svg";
// import leftArrowWhiteImg from "@assets/icons/leftArrowWhite.svg";
import http from "@apiServices/http";
import urls from "@apiServices/apiUrls";
import firestoreService from "@firestoreServices/firestoreServices";
import NewArticleCard from "@components/templates/NewArticleCard";
import SmallArticleCard from "@components/templates/SmallArticleCard";
import { useInView } from "react-cool-inview";
import DiscoverMoreWidget from "@components/templates/DiscoverMore";
import DiscoverMoreWithTagsWidget from "@components/templates/DiscoverMoreWidget";
import Head from "next/head";
import CSSTransition from "react-transition-group/CSSTransition";
import NavigationBar from "@components/pages/feedPage/NavigationBar/feedNavigationBar";
import * as clickhouse from "@components/pages/feedPage/ch-events";
import SectionHeading from "@components/ui-components/SectionHeading";
import ImgClose from "@assets/icons/closeDark.svg";
import ErrorPage from "@components/templates/emptyStates/pageView";
import { useTheme } from "next-themes";
import VideoArticle from "@components/templates/VideoArticle";

import FeedsWhiteImg from "@assets/icons/feedsWhite.svg";
import { disableScroll } from "@utils/disableScroll";
import {
  intervals,
  feedPageSize,
  feedPageLimit,
  downloadAppPopupDuration,
  globalfeedbackPopupDuration,
  hideNaukriEducationDuration
} from "@components/pages/feedPage/constants";

import { getBookmarkedArticles } from "@utils/articleUtils";
import NewArticleTracking from "@utils/newArticleCardTracking";
import ImageLoadingObserver from "@utils/imageLoadingObserver";
import { getFeedDetails } from "@utils/util";
import DownloadApp from "@components/templates/EndOfContent/downloadApp";
import { scrollToTop } from "@utils/scrollToTop";
import EndOfContent from "@components/templates/EndOfContent";
import ErrorEndOfContent from "@components/templates/EndOfContent/error";
import getUserPreferences from "@utils/getUserPreferences";
import { getCookies } from "cookies-next";
import s from "./feed.module.scss";
import useHash from "@hooks/useHash";
import Link from "next/link";
import Button from "@components/ui-components/Button";
import dayjs from "dayjs";
import ClickOutside from "@components/ui-components/ClickOutside";
import BigShyftPitchCard from "./BigShyftPitchCard";

const StoryPlayer = dynamic(() => import("@components/StoryPlayer"), {
  ssr: false
});
const TrendingArticles = dynamic(() => import("@components/templates/TrendingArticles"));
const CustomizeYourFeed = dynamic(() => import("@components/pages/feedPage/CustomizeYourFeed"));
const EmptyStateView = dynamic(() => import("@components/templates/emptyStates/pageView"));
const SignMeUpWidget = dynamic(() => import("@components/templates/SignMeUpWidget"));
const DownloadAppWidget = dynamic(() => import("@components/templates/DownloadAppWidget"));
const Toast = dynamic(() => import("@components/ui-components/Toast"));
const FeedbackModal = dynamic(() => import("@components/templates/FeedbackModal"));
const StoryWidget = dynamic(() => import("@components/templates/StoryWidget"));
const StoriesWidget = dynamic(() => import("@components/templates/StoriesWidget"));
const NewswrapVideo = dynamic(() => import("@components/newswrapVideo"));
const WordCoachWidget = dynamic(() => import("@components/templates/WordCoachWidget"));

let ALL_TAGS = null;
let followingTagId = undefined;
let apiSavedKeywords = [];
let isReturnFromCustomizeYourFeed = false;
const widgets = [
  "trending",
  "download",
  "subscribe",
  "discoverMore",
  "secondaryDiscoverMore",
  "discoverMoreWithTags",
  "trendingTopics",
  "wordCoach",
  "BigShyftPitchCard"
  // "story1",
  // "story2",
  // "story3",
  // "story4",
  // "story5"
];
let showArticleCard = false;
let storyPage = 0;

const Feed = ({
  closeDownloadAppDrawer,
  _selectedPreferenceData = [],
  followingPreferencesData = [],
  savedKeywordsData = [],
  trending = true,
  suggestedPreferencesData = [],
  stories = [],
  feedSummaryDetailData = [],
  discoverMoreTagsData = [],
  trendingTopicsData = [],
  secondaryDiscoverMoreTagsData = [],
  trendingArticlesData = [],
  allPrefs = "",
  isSignUpState = false,
  userLikeArticlesData = [],
  savedKeywordInitialData = [],
  userBookmarkArticlesData = [],
  preferenceIdsData = [],
  pageError: _pageErrorFlag = false,
  excludeTrendingFeedIds = [],
  isTrendingFeedFetchedOnServer,
  nextPage,
  googleWebStoriesData = [],
  showNewswraponPageLoad = false,
  newswrapDetails = null,
  feedDetail = []
}: any) => {
  const router = useRouter();
  const { pathname } = useRouter();
  const { query } = router;
  const { id, articleIds, share } = query;
  const { theme } = useTheme();
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState(feedSummaryDetailData);
  const [removeCount, setRemoveCount] = useState(0);
  const [isSignUp, setIsSignUp] = useState(isSignUpState);
  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [hideScrollToTop, setHideScrollToTop] = useState(true);

  // const [selectedPreference, setSelectedPreference] = useState(_selectedPreferenceData); // Need to review
  const [followingPreferences, setFollowingPreferences] = useState(followingPreferencesData);
  const [showMyFeedsButton, setShowMyFeedsButton] = useState(false);
  const [savedKeywords, setSavedKeywords] = useState(savedKeywordsData);
  const [isTrending, setIsTrending] = useState(trending);
  // const [feedPage, setFeedPage] = useState(_feedPageType);
  const [suggestedPreferences, setSuggestedPreferences] = useState(suggestedPreferencesData);
  // const [returningUser, setReturningUser] = useState(false);
  const [preferenceUpdatedLoading, setPreferenceUpdatedLoading] = useState(false);
  const [preferenceIds, setPreferenceIds] = useState(preferenceIdsData);
  const [savedKeywordInitial, setSavedKeywordInitial] = useState(savedKeywordInitialData);
  const [followingPreferencesState, handleFollowingPreferencesState] =
    useState(followingPreferencesData);
  // const [currentPage, setCurrentPage] = useState(
  //   feedSummaryDetailData.length && !isTrendingFeedFetchedOnServer ? 1 : 0
  // );
  const [currentPage, setCurrentPage] = useState(nextPage);
  const [isTrendingFeedFetchedOnServerState, setIsTrendingFeedFetchedOnServerState] = useState(
    isTrendingFeedFetchedOnServer
  );
  const [pageError, setPageError] = useState(_pageErrorFlag);
  const [userLikeArticles, setUserLikeArticles] = useState([]);
  const [userBookmarkArticles, setUserBookmarkArticles] = useState(userBookmarkArticlesData);
  const [discoverMoreTags, setDiscoverMoreTags] = useState(discoverMoreTagsData);
  const [trendingTopics, setTrendingTopics] = useState(trendingTopicsData);
  const [secondaryDiscoverMoreTags, setSecondaryDiscoverMoreTags] = useState(
    secondaryDiscoverMoreTagsData
  );
  const [trendingArticles, setTrendingArticles] = useState(trendingArticlesData);
  const [recent, setRecent] = useState(true);
  const [showOlderContent, setShowOlderContent] = useState(false);
  const [endOfFeed, setEndOfFeed] = useState(false);
  const [isFetchingFeedError, setIsFetchingFeedError] = useState(false);
  const [discoverMoreChips, setDiscoverMoreChips] = useState([]);
  // const [currentIndex, _setCurrentIndex] = useState(1);
  const [isFetchingSummary, setIsFetchingSummary] = useState(false);
  const [isFetchingTrendingFeeds, setIsFetchingTrendingFeeds] = useState(false);
  const [isFetchingTrendingTopics, setIsFetchingTrendingTopics] = useState(false);
  const [isFetchingDiscoverMoreTags, setIsFetchingDiscoverMoreTags] = useState(false);
  const [isNewVisible, setNewVisible] = useState(false);
  const [showMyFeedsToast, setShowMyFeedsToast] = useState(false);
  const [loadToast, setLoadToast] = useState(false);
  const [isStartPolling, setStartPolling] = useState(true);
  const [openCustomizedFeed, setOpenCustomizedFeed] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  const [observer, setObserver] = useState(null);
  const [imageObserver, setImageObserver] = useState(null);
  const [showGlobalDownloadAppPopup, setShowGlobalDownloadAppPopup] = useState(false);
  const [showEndOfContentDownloadAppCard, setShowEndOfContentDownloadAppCard] = useState(false);
  const [showDownloadAppPopup, setShowDownloadAppPopup] = useState(false);
  const [seenArticleList, setSeenArticleList] = useState([]);
  const [seenArticle, setSeenArticle] = useState(null);
  const [readArticleList, setReadArticleList] = useState([]);
  const [readArticle, setReadArticle] = useState(null);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showPlayerLoading, setShowPlayerLoading] = useState(false);
  const [isStoryWidgetClicked, setIsStoryWidgetClicked] = useState(false);
  const [isGoogleStoryWidgetClicked, setIsGoogleStoryWidgetClicked] = useState(false);
  const [storiesData, setStoriesData] = useState(stories);
  const [googleWebStoriesDetails, setGoogleWebStoriesDetails] = useState(googleWebStoriesData);
  const [isGoogleWebStoriesLoading, setIsGoogleWebStoriesLoading] = useState(true);
  const [showMiniPlayer, setShowMiniPlayer] = useState(true);
  const [newswrapData, setNewswrapData] = useState(newswrapDetails);
  const [showNewswrapOnLoad, setShowNewswrapOnLoad] = useState(false);
  const [showNewswrap, setShowNewswrap] = useState(false);
  const [mutedNewswrap, setMutedNewswrap] = useState(true);
  const [naukriEducation, setNaukriEducation] = useState(0);
  const [puzzles, setPuzzles] = useState([]);
  const [userPuzzles, setUserPuzzles] = useState([]);
  const [showBadgeEducation, setShowBadgeEducation] = useState(false);

  const value: any = id;
  let timer = null;
  let articleCount = 0;
  let downloadAppBannerTimer = null;
  const [hash, setHash]: any = useHash();

  const checkElementInView = () => {
    const container =
      document.getElementById("unsupported-browser-container") ||
      document.getElementById("container");

    const element = document.getElementById("wordCoach");

    if (!container || !element) return;

    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const containerTop = containerRect.top;
    const containerBottom = containerRect.bottom;
    const elementTop = elementRect.top;
    const elementBottom = elementRect.bottom;

    const offset = 90;

    if (elementTop >= containerTop && elementBottom <= containerBottom - offset) {
      //already in viewport
    } else {
      element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      container.style.scrollPaddingTop = "75px";
    }
  };

  // newswrap video to be shown on load only once
  useEffect(() => {
    let puzzlesTimer = null;
    if (window.location.hash === "#puzzles") {
      puzzlesTimer = setTimeout(() => {
        checkElementInView();
      }, 2000);
    }
    const newswrapAutoloadTimer = setTimeout(() => {
      if (
        showNewswraponPageLoad &&
        sessionStorage.getItem("newswrapOnLoad") !== feedDetail[0].id &&
        feedDetail[0].newsBiteType === "NEWS_WRAP" &&
        feedDetail.length > 0
      ) {
        setShowNewswrapOnLoad(true);
        sessionStorage.setItem("newswrapOnLoad", feedDetail[0].id);
        setTimeout(() => {
          clickhouse.trackStoryView({
            element_name: `onload_newswrap_story`,
            element_type: "story",
            actionType: "view",
            eventCategory: "story_view",
            data: {
              title: feedDetail[0].title
            }
          });
        }, 500);
      } else {
        setShowNewswrapOnLoad(false);
      }
    }, 2000);

    return () => {
      if (window.location.hash === "#puzzles") {
        window.location.hash = "";
      }
      clearTimeout(puzzlesTimer);
      clearTimeout(newswrapAutoloadTimer);
    };
  }, []);

  // naukri education
  useEffect(() => {
    const container =
      document.getElementById("unsupported-browser-container") ||
      document.getElementById("container");

    const handleOnScroll = () => {
      var scrollTop = container.scrollTop;
      var offsetHeight = container.offsetHeight;
      var clientHeight = container.clientHeight;
      if (offsetHeight <= scrollTop + clientHeight) {
        // This is called before scroll end!
        setNaukriEducation(0);
      }
    };
    container.addEventListener("scroll", handleOnScroll, false);

    () => {
      return container.removeEventListener("scroll", handleOnScroll);
    };
  }, []);
  // naukri education
  useEffect(() => {
    const isNaukriUser = pathname.split("/").includes("naukri");
    const date1 = dayjs(localStorage.getItem("naukri_education_date"));
    const now = dayjs();
    const diff = now.diff(date1, "d");
    if (
      isNaukriUser &&
      // googleWebStoriesDetails.length &&
      (!localStorage.getItem("naukri_education_date") || diff > 10) &&
      !localStorage.getItem("naukriEducation") &&
      data.length > 0
    ) {
      const educationTimer = setTimeout(() => {
        setNaukriEducation(1);
        // const container =
        //   document.getElementById("unsupported-browser-container") ||
        //   document.getElementById("container");

        // container.classList.add("disable-scroll");

        localStorage.setItem(
          "naukri_education_date",
          JSON.stringify(dayjs(new Date()).format("YYYY-MM-DD"))
        );
        clearTimeout(educationTimer);
      }, 400);

      const hideEducationTimer = setTimeout(() => {
        setNaukriEducation(0);
        clearInterval(hideEducationTimer);
      }, hideNaukriEducationDuration);

      return () => {
        clearTimeout(educationTimer);
        clearInterval(hideEducationTimer);
      };
    }
  }, []);
  // }, [googleWebStoriesDetails]);

  // to show newswrap video player on page load
  useEffect(() => {
    if (showNewswrapOnLoad) {
      setTimeout(() => {
        // const state = { timestamp: Date.now() };
        // window.history.replaceState({ state: "mystate" }, "", window.location.href);
        // window.history.pushState(state, "", `${window.location.href}#story`);

        //working for android
        // window.history.pushState({ state: "newstate" }, "", `${window.location.href}#story`);

        // const hash = window.location.hash;
        // window.history.pushState(
        //   { val: "video" },
        //   title,
        //   `${window.location.href.replace(/#.*$/, "")}`
        // );
        window.location.hash = "#story";

        // popup shouldn't be shown if story is shown
        clearTimeout(window.globalfeedbackPopup);
        clearTimeout(window.globalAppPopupTimer);

        // window.addEventListener("popstate", function (event) {
        //   // Check if there is a state object in the event
        //   console.log({ event: event });
        //   // if (event.state) {
        //   //   // Reload the page content based on the URL in the history object
        //   //   router.push(window.location.href);
        //   // }
        // });
      }, 500);
    }
  }, [showNewswrapOnLoad]);

  // const isNaukriUser = pathname.split("/").includes("naukri");
  const { observe: endOfContentObserver } = useInView({
    onEnter: () => {
      // Triggered when the target enters the viewport

      // const container =
      //   document.getElementById("unsupported-browser-container") ||
      //   document.getElementById("container");
      // DISABLE THE GLOBAL APP DOWNLOAD POPUP
      downloadAppBannerTimer = setTimeout(() => {
        if (window.globalAppPopupTimer) {
          clearTimeout(window.globalAppPopupTimer);
          window.globalAppPopupTimer = null;
        }

        // DISABLE THE DOWNLOAD APP any POPUP IS SHOWN
        if (
          (!localStorage.getItem("feedback") && seenArticleList.length < 7) ||
          localStorage.getItem("feedback")
        ) {
          setShowDownloadAppPopup(true);
        }
        // container.classList.add("disable-scroll");
      }, downloadAppPopupDuration);
    },
    onLeave: () => {
      setShowDownloadAppPopup(false);
    }
  });

  // To open naukri popup for 15th element
  // const articleTrackingHandler = (id: string) => {
  //   if (data[15] && data[15]["id"] === id) {
  //     if (!localStorage.getItem("popupSeen") && pathname.split("/").includes("naukri")) {
  //       // setShowPopup(true);
  //       container.classList.add("disable-scroll");
  //     }
  //   }
  // };

  const { observe } = useInView({
    // For better UX, we can grow the root margin so the data will be loaded earlier
    rootMargin: "50px 0px",
    // When the last item comes to the viewport
    onEnter: ({ unobserve }) => {
      // Pause observe when loading data

      // Load more data
      if (!showEndOfContentCard && !showEndOfContentDownloadAppCard) {
        setIsMoreLoading(true);
        unobserve();
        fetchMoreData();
      }
    }
  });

  const getUserFirestoreData = async () => {
    let userFirestoreData = JSON.parse(localStorage.getItem("userData")); // userData is being updated from firestore db in _app
    setUserLikeArticles(userFirestoreData?.likes || []);
    // setUserPuzzles(userFirestoreData?.puzzles || []);

    if (currentPage === 0) {
      setSavedKeywordInitial(userFirestoreData?.savedKeywords || []);
    }
    setSavedKeywords(userFirestoreData?.savedKeywords || []);

    setUserBookmarkArticles(getBookmarkedArticles({ userFirestoreData }) || []);
    if (userFirestoreData?.preferences) {
      setPreferenceIds(userFirestoreData?.preferences);
    }

    return userFirestoreData;
  };

  const getUserPreferenceDetails = async () => {
    const userData = await getUserFirestoreData();
    const data = await getUserPreferences({ userData });

    setFollowingPreferences(data.followingPreferencesData || []);
    handleFollowingPreferencesState(data.followingPreferencesData || []);
    setSuggestedPreferences(data.suggestedPreferencesData || []);
    setSavedKeywords(data.savedKeywordsData || []);
    setSavedKeywordInitial(data.savedKeywordsData || []);
    setPreferenceIds(userData?.preferences || []);

    let preferencesSelected =
      userData?.preferences?.length > 0 || userData?.savedKeywords?.length > 0;
    userData?.preferences?.length > 0 || userData?.savedKeywords?.length > 0;

    return preferencesSelected;
  };

  useEffect(() => {
    // handling the back button once the story player is shown, app should not close
    if (!hash && isGoogleStoryWidgetClicked) {
      const playerEl: any = document.body.querySelector("amp-story-player");

      const lightboxEl = document.getElementById("lightbox");
      document.body.classList.toggle("lightbox-open", false);
      lightboxEl.classList.add("closed");
      lightboxEl.style.zIndex = "0";
      playerEl?.pause();
      setShowPlayerLoading(false);
      window["storyWidget"] = null;
      window["seenStoryList"] = JSON.stringify([]);
      setIsStoryWidgetClicked(false);
      setIsGoogleStoryWidgetClicked(false);
      //to reset the stories to page 1
      localStorage.removeItem("amp-story-state");
    }
    // handling the back button once the newswrap video player is shown, app should not close
    if (!hash && showNewswrap) {
      setShowNewswrap(false);
    }
    // handling the back button once the newswrap video player is shown, app should not close
    if (!hash && showNewswrapOnLoad) {
      setShowNewswrapOnLoad(false);
    }
  }, [hash]);

  const getPuzzlesDetails = async () => {
    const firestore = new firestoreService();
    const userFirestoreData = await getUserFirestoreData();
    // const puzzles = await firestore.fetchUnseenPuzzles(userFirestoreData?.puzzles || []);
    const puzzles = await firestore.fetchUnseenPuzzles(userFirestoreData?.puzzles || [], "maths");
    setPuzzles(puzzles);
  };

  useEffect(() => {
    // if (isSignUpState) {
    //   localStorage.setItem("isSignUp", isSignUpState);
    // }

    const cookies = getCookies();
    const isSignUpCookie = cookies["isSignUp"] ? true : false;
    window["storyWidget"] = null;
    window["seenStoryList"] = JSON.stringify([]);
    setMounted(true);
    setIsSignUp(isSignUpCookie);
    if (pathname !== "my-feeds") {
      getUserPreferenceDetails().then((res) => {
        if (res) setShowMyFeedsButton(res);
      });
    }

    const intervalId = setInterval(() => {
      if (window.__bgtracker) {
        clickhouse.trackPageLoad();

        clearInterval(intervalId);
      }
    }, 500);

    localStorage.setItem("prefs", allPrefs);

    const tagData = allPrefs ? JSON.parse(allPrefs) : [];
    const tagsArr = tagData?.map((pref) => pref?.tags);
    const allTags = tagsArr?.flat();
    ALL_TAGS = allTags;
    const temp = [...seenArticleList];
    const readArticleListTemp = [...readArticleList];
    // const articleTrackingObserver = new ArticleTracking({}, articleTrackingHandler);

    // old article card tracker
    // const articleTrackingObserver = new ArticleTracking(
    //   { trackArticleViewEvent: true },
    //   (id) => {
    //     temp.push(id);
    //     setSeenArticle(id);
    //     setSeenArticleList(temp);
    //   },
    //   (id) => {
    //     readArticleListTemp.push(id);
    //     setReadArticle(id);
    //     setReadArticleList(readArticleListTemp);
    //   }
    // );

    //new article card tracker
    const articleTrackingObserver = new NewArticleTracking(
      { trackArticleViewEvent: true },
      (id) => {
        temp.push(id);
        setSeenArticle(id);
        setSeenArticleList(temp);
      },
      (id) => {
        readArticleListTemp.push(id);
        setReadArticle(id);
        setReadArticleList(readArticleListTemp);
      }
    );
    setObserver(articleTrackingObserver);
    // const imageLoadingObserver = new ImageLoadingObserver();
    // setImageObserver(imageLoadingObserver);

    //puzzles data
    getPuzzlesDetails();

    return () => {
      articleTrackingObserver.destroy();
      // imageLoadingObserver.destroy();
      const container =
        document.getElementById("unsupported-browser-container") ||
        document.getElementById("container");
      container?.classList.remove("disable-scroll");
      clearInterval(intervalId);
      clearTimeout(downloadAppBannerTimer);
    };
  }, []);

  useEffect(() => {
    if (readArticleList.length > 15 && !localStorage.getItem("feedback")) {
      setShowFeedbackPopup(true);
      const container =
        document.getElementById("unsupported-browser-container") ||
        document.getElementById("container");
      container.classList.add("disable-scroll");
    }
  }, [seenArticle]);

  useEffect(() => {
    if (followingPreferences.length > 0 || savedKeywords.length > 0) {
      setShowMyFeedsButton(true);
    } else {
      setShowMyFeedsButton(false);
    }
  }, [followingPreferences, savedKeywords]);

  useEffect(() => {
    const imageLoadingObserver = new ImageLoadingObserver();
    setImageObserver(imageLoadingObserver);

    return () => {
      imageLoadingObserver.destroy();
    };
  }, [theme]);

  const fetchDiscoverMoreTags = async () => {
    const userData = await getUserFirestoreData();
    let tagIds = "";
    (userData?.preferences || []).map((item, index) => {
      if (index === 0) {
        tagIds += `${item}`;
      } else {
        tagIds += `,${item}`;
      }
    });
    let url = urls.discoverMoreTags({ tagIds });
    return http
      .get(url)
      .then((res: any) => {
        setDiscoverMoreTags(res);
      })
      .catch(() => {})
      .finally(() => {
        setIsFetchingDiscoverMoreTags(false);
      });
  };

  const fetchTrendingTopics = async () => {
    let url = urls.trendingDiscoverMoreTags;

    return http
      .get(url)
      .then((res: any) => {
        if (res.primary && res.primary.length) {
          setTrendingTopics(res.primary.map((i) => [i.slug, i.slugDisplayName]));
        }
        if (res.secondary && res.secondary.length) {
          setSecondaryDiscoverMoreTags(res.secondary.map((i) => [i.slug, i.slugDisplayName]));
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsFetchingTrendingTopics(false);
      });
  };

  const fetchTrendingAricles = async () => {
    const userData = await getUserFirestoreData();
    return http
      .post(urls.trendingArticles, { tags: userData?.preferences || [] })
      .then((res: any) => {
        setTrendingArticles(res);
      })
      .catch(() => {})
      .finally(() => {
        setIsFetchingTrendingFeeds(false);
      });
  };

  const fetchFeedData = async ({
    page = currentPage,
    showOlderContent = false,
    articleIds = undefined,
    isInternal = true,
    isRecent = true
  }) => {
    // if (page === 0 && !isRecent) {
    //   setStartPolling(false);
    //   setNewVisible(false);
    // }
    const userFirestoreData = await getUserFirestoreData();

    // if (page == 0) {
    followingTagId =
      userFirestoreData?.preferences?.length > 0 ? userFirestoreData?.preferences : [];
    apiSavedKeywords = userFirestoreData?.savedKeywords || [];
    if (query.savedKeyword && !isReturnFromCustomizeYourFeed) {
      const firestore = new firestoreService();
      await firestore.addSavedKeywords(query.savedKeyword);
      apiSavedKeywords = [...new Set([...apiSavedKeywords, query.savedKeyword])];
    }
    setSavedKeywords(apiSavedKeywords);
    // }

    const excludeArticleIds = trending
      ? !isInternal
        ? [
            ...(userFirestoreData?.report || []),
            ...(userFirestoreData?.notInterested || []),
            ...(getBookmarkedArticles({ userFirestoreData }) || []),
            ...excludeTrendingFeedIds
          ]
        : [
            ...(userFirestoreData?.report || []),
            ...(userFirestoreData?.notInterested || []),
            ...(getBookmarkedArticles({ userFirestoreData }) || [])
          ]
      : [
          ...(userFirestoreData?.report || []),
          ...(userFirestoreData?.notInterested || []),
          ...(getBookmarkedArticles({ userFirestoreData }) || [])
        ];
    return http
      .post(trending ? urls.trending : urls.feed, {
        tags: followingTagId?.length > 0 ? followingTagId : undefined,
        preferencesSkipped:
          !!!apiSavedKeywords?.length && !!!followingTagId?.length ? true : undefined,
        savedKeywords: apiSavedKeywords || [],
        articlesToExclude: excludeArticleIds,
        recent: isRecent,
        // recent: recent !showOlderContent,
        page: page,
        size: feedPageSize,
        articleIds: articleIds
      })
      .then((res: any) => {
        let feedTemp = [];

        // if ((isReturnFromCustomizeYourFeed || page === 0) && !showOlderContent && ) {
        //   feedTemp = JSON.parse(JSON.stringify([...res]));
        // } else {

        if (isReturnFromCustomizeYourFeed) {
          feedTemp = [...res];
          setShowEndOfContentDownloadAppCard(false);
        } else {
          feedTemp = [...data, ...res];
        }
        // else if (data.length <= feedPageLimit) {
        //   let newData = JSON.parse(JSON.stringify([...data, ...res]));
        //   if (newData.length > feedPageLimit) {
        //     newData = newData.slice(0, feedPageLimit);
        //     setShowEndOfContentDownloadAppCard(true);
        //   } else {
        //     setShowEndOfContentDownloadAppCard(false);
        //   }
        //   feedTemp = newData;
        // } else {
        //   feedTemp = data.slice(0, feedPageLimit);
        //   setShowEndOfContentDownloadAppCard(true);
        // }

        if (isReturnFromCustomizeYourFeed) {
          // if (isTrendingState) {
          //   setShowMyFeedsToast(true);
          // }
        }
        isReturnFromCustomizeYourFeed = false;
        if (!res || res.length < feedPageSize) {
          if (!trending) {
            if (!isRecent && showOlderContent) {
              setEndOfFeed(true);
            } else if (isRecent && !isTrending) {
              setRecent(false);
            }
          } else {
            // if (isRecent && !isTrending) {
            //   setRecent(false);
            // } else {
            //   setEndOfFeed(true);
            // }
            // if (!isRecent && isTrending) {
            setEndOfFeed(true);
            // }
          }
        }
        setData(feedTemp);
        setCurrentPage(page + 1);
        setPageError(false);
        setStartPolling(true);
      })
      .catch(() => {
        setStartPolling(false);
        // if (page > 0) {
        setIsFetchingFeedError(true);
        setShowEndOfContentDownloadAppCard(false);
        // } else {
        //   setPageError(true);
        // }
      })
      .finally(() => {
        setIsFetchingSummary(false);

        if (
          page > 0 ||
          (page === 0 && !isRecent) ||
          (isTrending && isTrendingFeedFetchedOnServerState && currentPage === 0)
        ) {
          setIsMoreLoading(false);
        }
      });
  };

  const fetchMoreData = async () => {
    setIsFetchingSummary(true);
    await fetchFeedData({
      page: currentPage,
      isInternal: !isTrendingFeedFetchedOnServerState,
      isRecent: recent,
      showOlderContent: trending ? false : !showEndOfContentCard && !recent ? true : false
    });
  };

  useEffect(() => {
    if (
      !isFetchingSummary &&
      !isFetchingTrendingFeeds &&
      !isFetchingDiscoverMoreTags &&
      !isFetchingTrendingTopics &&
      mounted
    ) {
      const dataTemp = JSON.parse(JSON.stringify(data));
      const params = {
        feedTemp: dataTemp,
        isTrending: trending,
        hasDiscoverMoreTags: discoverMoreTags.length,
        hasTrendingTopics: trendingTopics.length,
        hasSecondaryDiscoverMoreTags: secondaryDiscoverMoreTags.length,
        isSignUp,
        pathname,
        hasTrendingArticles: trendingArticles.length
      };
      const updatedData = getFeedDetails(params);
      setData(updatedData);
      setPreferenceUpdatedLoading(false);
    }
  }, [
    isFetchingSummary,
    isFetchingTrendingFeeds,
    isFetchingDiscoverMoreTags,
    isFetchingTrendingTopics
  ]);

  const removeNotInterestedArticle = (articleId) => {
    setData(data.filter((searchData) => searchData?.id !== articleId));
  };

  const { observe: firstElementObserve } = useInView({
    threshold: 0.25, // Default is 0
    onEnter: () => {
      // Triggered when the target enters the viewport
      setHideScrollToTop(true);
    },
    onLeave: () => {
      // Triggered when the target leaves the viewport
      if (!preferenceUpdatedLoading) {
        // show scroll top only when first article scrolled
        setHideScrollToTop(false);
      }
    }
  });

  useEffect(() => {
    if (preferenceUpdatedLoading) {
      setHideScrollToTop(true);
    }
  }, [preferenceUpdatedLoading]);

  const updateReturnFromCustomizeYourFeed = () => {
    isReturnFromCustomizeYourFeed = true;
  };

  useEffect(() => {
    if (isStartPolling) {
      timer = setTimeout(() => {
        getPollingData();
      }, intervals.Polling);
    } else {
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isStartPolling]);

  const repeatPolling = (value = false) => {
    if (value != isNewVisible) {
      setNewVisible(value);
    }

    timer = setTimeout(() => {
      getPollingData();
    }, intervals.Polling);
  };

  const getPollingData = async () => {
    try {
      const userFirestoreData = await getUserFirestoreData();
      http
        .post(trending ? urls.pollingTrendingUrl : urls.pollingUrl, {
          tags: followingTagId?.length > 0 ? followingTagId : undefined,
          savedKeywords: apiSavedKeywords || [],
          articlesToExclude: [
            ...(userFirestoreData?.report || []),
            ...(userFirestoreData?.notInterested || []),
            ...(getBookmarkedArticles({ userFirestoreData }) || [])
          ],
          // recent: recent || !showOlderContent,
          recent: true, //recent
          page: 0,
          size: feedPageSize
        })
        .then((res) => {
          if (res) {
            clearTimeout(timer);
            setNewVisible(true);
          } else {
            repeatPolling(false);
          }
        })
        .catch(() => {
          repeatPolling(false);
        });
    } catch {
      repeatPolling(false);
    }
  };

  const getFollowingStatus = (articleData) => {
    return (
      preferenceIds?.includes(articleData?.tagIds?.[0]) || savedKeywordInitial?.length > 0 || false
    );
  };

  const updateSavedKeywords = (value = "", isDelete = false) => {
    if (isDelete) {
      setSavedKeywords(savedKeywords?.filter((keyword) => keyword != value) || []);
    } else {
      setSavedKeywords([...savedKeywords, value]);
    }
  };

  const updatePreferences = (articleData, chipData = null, isDelete = false) => {
    let selectedTag = null;

    if (!!articleData) {
      selectedTag = ALL_TAGS.filter((tag) => tag?.id == articleData?.tagIds?.[0])?.[0] || null;
    } else {
      selectedTag = chipData || null;
    }

    //need to discuss @vivek
    if (!!selectedTag) {
      selectedTag.isSelected = true;
    }
    if (isDelete) {
      handleFollowingPreferencesState(
        followingPreferencesState?.filter((pref) => pref.id !== articleData?.tagIds?.[0]) || []
      );
    } else {
      handleFollowingPreferencesState([...followingPreferencesState, selectedTag]);
    }
  };

  const updateTagsPreferencesWithDiscoverMoreWidget = (
    articleData,
    chipData = null,
    isDelete = false
  ) => {
    let selectedTag = null;

    if (!!articleData) {
      selectedTag = ALL_TAGS.filter((tag) => tag?.id == articleData?.tagIds?.[0])?.[0] || null;
    } else {
      chipData.isSelected = isDelete ? false : true;
      selectedTag = chipData || null;
    }

    if (isDelete) {
      handleFollowingPreferencesState(
        followingPreferencesState?.filter((pref) => pref.id !== selectedTag.id) || []
      );
    } else {
      handleFollowingPreferencesState([...followingPreferencesState, selectedTag]);
    }
  };

  let imgToPreload = [];
  if (data.length) {
    let count = 0;
    for (let i = 0; i < data.length && count < 2; i++) {
      if (!(typeof data[i] === "string" && widgets.includes(data[i]))) {
        if (data[i]?.mediaURL) {
          imgToPreload.push(data[i].mediaURL);
          count++;
        }
      }
    }
  }

  useEffect(() => {
    //@ts-ignore GLOBAL APP DOWNLOAD POPUP
    // if (!window["globalAppPopup"] && !window.__popup && localStorage.getItem("feedback")) {
    //   window.globalAppPopupTimer = setTimeout(() => {
    //     setShowGlobalDownloadAppPopup(true);
    //     //@ts-ignore
    //     window.__popup = true;
    //   }, globalAppDownloadDuration);
    // }
    // const pageName = getPageName();
    // feed back popup after 1 minutes 30 seconds
    if (!window.__popup && !localStorage.getItem("feedback")) {
      window.globalfeedbackPopup = setTimeout(() => {
        setShowFeedbackPopup(true);
        const container =
          document.getElementById("unsupported-browser-container") ||
          document.getElementById("container");
        container.classList.add("disable-scroll");
        //@ts-ignore
        window.__popup = true;
      }, globalfeedbackPopupDuration);
    }

    return () => {
      //@ts-ignore
      clearTimeout(window.globalAppPopupTimer);
      // clearTimeout(window.globalfeedbackPopup);
    };
  }, []);

  const showOlderContentCard =
    ((!recent && !showOlderContent) ||
      (data.length < feedPageLimit && !isTrending && !showOlderContent)) &&
    !isFetchingFeedError &&
    !isMoreLoading &&
    !isTrending &&
    !showEndOfContentDownloadAppCard;

  const showEndOfContentCard =
    endOfFeed &&
    !isFetchingFeedError &&
    !isMoreLoading &&
    !showEndOfContentDownloadAppCard &&
    !showOlderContentCard;

  const markStorySeen = async (id) => {
    const url = urls.googleWebStorySeen(id);
    return http
      .post(url, {})
      .then((res: any) => {})
      .catch(() => {});
  };

  const getStoryDetail = (index, isCircularWidget = false) => {
    const playerEl: any = document.body.querySelector("amp-story-player");
    let currentIndex = index;

    // updating the story seen status for story widget
    // const temp = [...googleWebStoriesDetails];
    // temp[index].seen = true;
    // setGoogleWebStoriesDetails(temp);

    const lightboxEl = document.getElementById("lightbox");
    if (playerEl && lightboxEl) {
      lightboxEl.classList.remove("closed");
      lightboxEl.style.zIndex = "2000";
      // setShowPlayerLoading(true);
      var xDown = null;
      var yDown = null;

      //to reset the stories to page 1
      localStorage.removeItem("amp-story-state");

      const url = isCircularWidget
        ? `${process.env.NEXT_PUBLIC_STORIES_BASE_URL}/web-story/${googleWebStoriesDetails[index].slug}`
        : `${process.env.NEXT_PUBLIC_STORIES_BASE_URL}/web-story/${stories[index].slug}`;

      playerEl?.show(url, /* pageId */ null, {
        animate: false
      });
      if (window["storyWidget"] === null) {
        window["storyWidget"] = index;
      }
      document.body.classList.toggle("lightbox-open");

      playerEl?.addEventListener("amp-story-player-touchstart", (event) => {
        function getTouches(event) {
          return event.detail.touches;
        }

        const firstTouch = getTouches(event)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
      });

      playerEl?.addEventListener("amp-story-player-touchmove", (event) => {
        if (!xDown || !yDown) {
          return;
        }

        var xUp = event.detail.touches[0].clientX;
        var yUp = event.detail.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          /*most significant*/
          if (xDiff > 0) {
            /* right swipe */
            window["swipe"] = "right";
          } else {
            /* left swipe */
            window["swipe"] = "left";
          }
        } else {
          if (yDiff > 0) {
            /* down swipe */
            window["swipe"] = "down";
          } else {
            /* up swipe */
            window["up"] = "up";
          }
        }
        /* reset values */
        xDown = null;
        yDown = null;
      });

      clickhouse.trackStoryView({
        element_name: isCircularWidget ? `circular_story_widget` : `story_widget`,
        element_type: "story",
        actionType: "view",
        eventCategory: "story_view",
        data: {
          slug: isCircularWidget ? googleWebStoriesDetails[index].slug : stories[index].slug,
          widget_position: index + 1
        }
      });

      //once the slide of the story changes
      playerEl?.addEventListener("storyNavigation", (event) => {
        const temp = [...googleWebStoriesDetails];
        if (!temp[index].seen) {
          markStorySeen(temp[currentIndex].id);
        }

        storyPage = storyPage + 1;

        // updating the story seen status for story widget once the slide is seen
        temp[index].seen = true;
        setGoogleWebStoriesDetails(temp);

        clickhouse.trackCTAs({
          element_name: isCircularWidget ? `circular_story_widget` : `story_widget`,
          element_type: "story",
          actionType: "slide_view",
          eventCategory: "slide_view",
          data: {
            slug: isCircularWidget
              ? googleWebStoriesDetails[window["storyPlayingIndex"]].slug
              : stories[window["storyPlayingIndex"]].slug,
            widget_position: index + 1,
            page: storyPage
          }
        });
      });

      // once the story changes in the story player
      playerEl?.addEventListener("navigation", (event) => {
        storyPage = 0;
        window["storyPlayingIndex"] = event.detail.index;

        //update story seen status
        currentIndex = currentIndex + 1;
        const temp = [...googleWebStoriesDetails];
        if (temp[currentIndex] && currentIndex) {
          temp[currentIndex].seen = true; // for marking the circular widget seen
          markStorySeen(temp[currentIndex].id);
        }
        setGoogleWebStoriesDetails(temp);
        // auto close on swipe for iphone and ipad
        // if (navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad")) {
        //   // document.body.classList.toggle("lightbox-open", false);
        //   // lightboxEl.classList.add("closed");
        //   // lightboxEl.style.zIndex = "0";
        //   // playerEl.pause();
        //   // setShowPlayerLoading(false);
        //   // window["storyWidget"] = null;
        //   // window["seenStoryList"] = JSON.stringify([]);
        //   // //to reset the stories to page 1
        //   // localStorage.removeItem("amp-story-state");
        // } else {
        // close the player if the visible story index is same as widget story index
        const storyList = new Set(JSON.parse(window["seenStoryList"]));
        if (JSON.parse(window["seenStoryList"]).length >= 9) {
          if (
            event.detail.index === window["storyWidget"] &&
            window["swipe"] === "right" &&
            JSON.parse(window["seenStoryList"]).length >= 9 // currently we fetch 10 stories and this should be length - 1
          ) {
            document.body?.classList.toggle("lightbox-open", false);
            lightboxEl?.classList.add("closed");
            lightboxEl.style.zIndex = "0";
            playerEl?.pause();
            setShowPlayerLoading(false);
            window["storyWidget"] = null;
            window["seenStoryList"] = JSON.stringify([]);

            //to reset the stories to page 1
            localStorage.removeItem("amp-story-state");
          }
        } else {
          storyList.add(event.detail.index);
          window["seenStoryList"] = JSON.stringify([...storyList]);
        }

        if (event.detail.index !== window["storyWidget"]) {
          clickhouse.trackStoryView({
            element_name: `circular_story_widget`,
            element_type: "story",
            actionType: "view",
            eventCategory: "story_view",
            data: {
              slug: googleWebStoriesDetails[event.detail.index].slug,
              widget_position: index + 1
            }
          });
        }
        // }
      });
      playerEl?.play();
      clickhouse.trackCTAs({
        element_name: isCircularWidget ? `circular_story_widget` : `story_widget`,
        element_type: "story",
        eventCategory: "click",
        data: {
          slug: isCircularWidget
            ? googleWebStoriesDetails[window["storyPlayingIndex"]].slug
            : stories[window["storyPlayingIndex"]].slug,
          widget_position: index + 1
        }
      });
    }
  };

  const fetchGoogleWebStories = async () => {
    let url = urls.googleWebStories;
    http
      .post(url, {})
      .then((res: any) => {
        setGoogleWebStoriesDetails(res);
      })
      .catch(() => {})
      .finally(() => {
        setIsGoogleWebStoriesLoading(false);
      });
  };

  useEffect(() => {
    // const temp = [...data];
    // const newswrapTemp = temp.find((el) => el.newsBiteType === "NEWS_WRAP");
    // console.log({ newswrapTemp });
    // if (data.length && newswrapTemp) {
    //   setNewswrapData(newswrapTemp);
    //   const temp1 = temp.filter((item) => item.id !== newswrapTemp.id);
    //   setData(temp1);
    // }
    // fetchGoogleWebStories();
  }, []);

  function closeStoryPlayer() {
    const playerEl = document.body.querySelector("amp-story-player");
    playerEl?.remove();
    const lightboxEl = document.getElementById("lightbox");
    document.body?.classList.toggle("lightbox-open", false);
    lightboxEl?.classList.add("closed");
    lightboxEl.style.zIndex = "0";
    setShowPlayerLoading(false);
    localStorage.removeItem("amp-story-state");
    storyPage = 0;
    router.back();
    window["seenStoryList"] = JSON.stringify([]);
    setShowPlayerLoading(false);
    window["storyWidget"] = null;
    if (navigator.userAgent.includes("iPhone") || navigator.userAgent.includes("iPad")) {
      setIsGoogleStoryWidgetClicked(false);
    }
  }

  const title = feedDetail.length ? feedDetail[0].title : "";
  const description = feedDetail.length ? feedDetail[0]?.summary?.split("</li>")[0].slice(8) : null;

  let newswrapVidRef = useRef<HTMLVideoElement>(null);

  return (
    <>
      <Head>
        <title>{"Techminis | Providing short summaries for crisp and quick read"}</title>
        <meta name="description" content={"Providing short summaries for crisp and quick read"} />
        {feedDetail.length && pathname.split("/").includes("naukri") ? (
          <meta
            property="og:title"
            content={title || "Techminis | Providing short summaries for crisp and quick read"}
            key="og:title"
          />
        ) : (
          <meta
            property="og:title"
            content="Techminis | Providing short summaries for crisp and quick read"
            key="og:title"
          />
        )}
        {feedDetail.length && pathname.split("/").includes("naukri") ? (
          <meta
            property="og:description"
            content={description || "Providing short summaries for crisp and quick read"}
            key="og:description"
          />
        ) : (
          <meta
            property="og:description"
            content="Providing short summaries for crisp and quick read"
            key="og:description"
          />
        )}
        {feedDetail.length && pathname.split("/").includes("naukri") && articleIds ? (
          <meta property="og:image" content={feedDetail[0].mediaURL} key="og:image" />
        ) : (
          <meta property="og:image" content="/brandingImage.png" />
        )}
        <link rel="canonical" href="https://techminis.com" key="canonical" />
        {theme === "light" && (
          <meta name="theme-color" content="#F4F5F7" media="(prefers-color-scheme: light)"></meta>
        )}
        {theme === "dark" && (
          <meta name="theme-color" content="#171D27" media="(prefers-color-scheme: light)"></meta>
        )}
        {!!!data?.length && <meta name="robots" content="noindex" key="robots" />}
        {imgToPreload.length
          ? imgToPreload.map((img, ind) => <link key={ind} rel="preload" as="image" href={img} />)
          : null}
      </Head>
      {pageError && (
        <ErrorPage
          className="h-full"
          followingPreferences={followingPreferences}
          suggestedPreferences={suggestedPreferences}
          savedKeywords={savedKeywords}
          noNavigationBar={false}
          type="feedsError500"
          onClick={() => {
            // const userData = JSON.parse(localStorage.getItem("userData"));
            // const prefs = JSON.parse(localStorage.getItem("prefs"));
            // const selectedPreferences = [];
            // prefs.map((category) => {
            //   category.tags.map((tag) => {
            //     if (userData?.preferences?.includes(tag.id)) {
            //       tag.isSelected = true;
            //       selectedPreferences.push(tag);
            //     }
            //   });
            // });
            if (followingPreferencesState.length === 0 && savedKeywords.length === 0) {
              setIsTrending(true);
              // localStorage.setItem("feedPage", "isTrending");
              // if (isNaukriUser) {
              //   router.push(`/naukri${window.location.search}`);
              // } else {
              router.push("/");
              // }
            } else {
              // if (isNaukriUser) {
              //   router.push(`/naukri/my-feeds${window.location.search}`);
              // } else {
              router.push("/my-feeds");
              // }
              // setIsFetchingTrendingFeeds(true);
              // fetchTrendingAricles();
            }
            // setFollowingPreferences(selectedPreferences);
            // setSavedKeywordInitial(userData?.savedKeywords || []);

            // setPageError(false);
            // setPreferenceUpdatedLoading(true);
            // setCurrentPage(0);
            // getUserFirestoreData();
            // setIsFetchingSummary(true);
            // fetchFeedData({ page: 0 });
            // setIsTrendingFeedFetchedOnServerState(false);
            // if (isTrending) {
            //   setIsFetchingDiscoverMoreTags(true);
            //   fetchDiscoverMoreTags();
            // } else {
            //   setIsFetchingTrendingTopics(true);
            //   fetchTrendingTopics();
            // }
            scrollToTop();
          }}
        />
      )}
      {!pageError && (
        <>
          {/* {naukriEducation === 1 ? (
            <>
              <div
                className="w-full bg-[#00000047] dark:bg-[#ffffff47] xs:w-[480px]"
                style={{
                  position: "absolute",
                  top: 0,
                  height: "73px",
                  zIndex: 2000
                }}
              ></div>
              <div
                className="w-full bg-[#00000047] dark:bg-[#ffffff47] xs:w-[480px]"
                style={{
                  position: "absolute",
                  top: 259,
                  height: "calc(100vh - 252px)",
                  zIndex: 2000
                }}
              ></div>
              <div className="flex justify-center">
                <div
                  className={cx(
                    s.storiesPopup,
                    s.arrowTop,
                    theme === "dark" ? "before:bg-DN050" : "before:bg-N100"
                  )}
                >
                  <div className={cx(s.popupWrapper, "bg-N100 dark:bg-DN050")}>
                    <p className="text-center text-readingTextLarge leading-readingTextLarge text-N700 dark:text-DN700">
                      📢 <span className="font-bold">Introducing Stories 🎉 </span>
                      <br />
                      Immersive Content from Tech World!
                    </p>
                    <Button
                      className="m-auto mt-4 bg-[#F94929] py-1.5"
                      onClick={(e) => {
                        const timer = setTimeout(() => {
                          setNaukriEducation(2);
                          clearTimeout(timer);
                          const date1 = dayjs(localStorage.getItem("naukri_education_date"));
                          const now = dayjs();
                          const diff = now.diff(date1, "d");
                          if (diff > 10) {
                            localStorage.setItem("naukriEducation", "seen");
                          }
                          const container =
                            document.getElementById("unsupported-browser-container") ||
                            document.getElementById("container");

                          container.classList.remove("disable-scroll");
                        }, 200);

                        clickhouse.trackCTAs({
                          element_name: `story_widget_education_continue`,
                          element_type: "button",
                          actionType: "click",
                          eventCategory: "click"
                        });
                      }}
                      variant="button"
                      appearance="primary"
                      size="regular"
                      width="149px"
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : naukriEducation === 2 ? ( */}
          {naukriEducation === 1 ? (
            <>
              <ClickOutside
                handleClickOutside={(e) => {
                  setNaukriEducation(0);
                }}
              >
                <div
                  className={s.animationContainer}
                  id="education-scroll-container"
                  style={{
                    background: "linear-gradient(0deg, #F9492940 -16.26%, rgba(0, 0, 0, 0) 100%)",
                    bottom: query.app ? "0px" : "22px"
                  }}
                  onClick={() => {
                    const element = document.getElementById(data[0].id);
                    element.scrollIntoView({
                      block: "start",
                      behavior: "smooth"
                    });
                    clickhouse.trackCTAs({
                      element_name: `article_education_scroll`,
                      element_type: "button",
                      actionType: "click",
                      eventCategory: "click"
                    });
                  }}
                >
                  <svg className={s.arrows}>
                    <path className={s.a1} d="M0 0 L30 32 L60 0"></path>
                    <path className={s.a2} d="M0 20 L30 52 L60 20"></path>
                    <path className={s.a3} d="M0 40 L30 72 L60 40"></path>
                  </svg>
                  <div
                    onClick={() => {
                      const element = document.getElementById(data[0].id);
                      element.scrollIntoView({
                        block: "start",
                        behavior: "smooth"
                      });
                    }}
                    className="opacity mt-4 flex w-[92px] max-w-[92px] justify-center rounded-2xl bg-[#F94929CC] px-2 py-1 text-center text-readingTextLarge font-semiBold text-[#fff] xs:w-[480px]"
                  >
                    <p className="">Scroll</p>
                  </div>
                </div>
              </ClickOutside>

              {/* <div
                className="w-full xs:w-[480px]"
                style={{
                  background: "#00000047",
                  position: "absolute",
                  top: 0,

                  height: "309px",
                  zIndex: 2000
                }}
              ></div>
              <div
                className="w-full xs:w-[480px]"
                style={{
                  background: "#00000047",
                  position: "absolute",
                  top: 659,
                  height: `calc(100vh - 249px)`,
                  zIndex: 2000
                }}
              ></div>
              <div className="flex justify-center">
                <div className={cx(s.articleEducationPopup, s.arrowBottom)}>
                  <div className={s.popupWrapper}>
                    <p className="dark:bg bg-N100 text-center text-readingTextLarge leading-readingTextLarge text-N700">
                      ⬇️ <span className="font-bold">Scroll Down</span> to dive into the world of
                      exciting news! ⬇️
                    </p>
                    <Button
                      className="m-auto mt-4 py-1.5"
                      onClick={(e) => {
                        const timer = setTimeout(() => {
                          setNaukriEducation(0);
                          clearTimeout(timer);
                          localStorage.setItem("naukriEducation", "seen");
                          const container =
                            document.getElementById("unsupported-browser-container") ||
                            document.getElementById("container");

                          container.classList.remove("disable-scroll");
                        }, 200);
                      }}
                      variant="button"
                      appearance="primary"
                      size="regular"
                      width="149px"
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>{" "} */}
            </>
          ) : null}
          {mounted && (
            <CSSTransition
              in={showNewswrap || showNewswrapOnLoad}
              class={
                showNewswrapOnLoad
                  ? "fixed top-[157px] left-[57px] z-[10000] h-[0px] w-[0px] overflow-hidden bg-transparent opacity-[0] duration-500"
                  : "fixed top-[157px] left-[57px] z-[10000] h-[0px] w-[0px] overflow-hidden bg-transparent opacity-[0] duration-500"
              }
              timeout={500}
              unmountOnExit
              classNames={{
                enterActive: s.storyZoomIn,
                enterDone: s.storyZoomIn,
                apearDone: s.storyZoomIn,
                appear: s.storyZoomIn,
                exit: s.storyZoomOut
              }}
            >
              <div>
                <NewswrapVideo
                  newswrapVidRef={newswrapVidRef}
                  articleData={newswrapData}
                  isMuted={mutedNewswrap}
                  showNewswrap={showNewswrap}
                  showNewswrapOnLoad={showNewswrapOnLoad}
                  setShowNewswrap={() => {
                    setShowNewswrap(false);
                    setShowNewswrapOnLoad(false);
                    // router.back();
                  }}
                  handleOnUpdateNewswrapData={(obj) => {
                    if (obj.id === newswrapData.id) {
                      let temp = { ...newswrapData };
                      temp = obj;
                      setNewswrapData(temp);
                    }
                  }}
                />
              </div>
            </CSSTransition>
          )}
          <NavigationBar
            followingPreferences={followingPreferencesState}
            savedKeywords={savedKeywords}
            handleOnSignup={() => {
              let feedDetailTemp = [...data];
              feedDetailTemp = feedDetailTemp.filter((feed) => feed !== "subscribe");
              setData(feedDetailTemp);
            }}
            headerPosition="sticky" //fixed, to be updated when stories are enabled
            onLogoClick={() => {
              scrollToTop();
              // router.reload();

              // Redirect to trending page
              router.push("/");

              // GA event from featured section - To be review
              // clickhouse.trackBackToTopClick({
              //   element_name: "backtotop_button",
              // });
              // const userData = JSON.parse(localStorage.getItem("userData"));
              // const prefs = JSON.parse(localStorage.getItem("prefs"));
              // const selectedPreferences = [];
              // prefs.map((category) => {
              //   category.tags.map((tag) => {
              //     if (userData?.preferences?.includes(tag.id)) {
              //       tag.isSelected = true;
              //       selectedPreferences.push(tag);
              //     }
              //   });
              // });

              // if (followingPreferences.length === 0 && savedKeywords.length === 0) {
              //   setIsTrending(true);
              //   localStorage.setItem("feedPage", "isTrending");
              //   if (isNaukriUser) {
              //     router.push(`/naukri${window.location.search}`);
              //   } else {
              //     router.push("/");
              //   }
              // } else {
              //   if (isNaukriUser) {
              //     router.push(`/naukri/my-feeds${window.location.search}`);
              //   } else {
              //     router.push("/my-feeds");
              //   }
              //   // setIsFetchingTrendingFeeds(true);
              //   // fetchTrendingAricles();
              // }
              // setFollowingPreferences(selectedPreferences);
              // setSavedKeywordInitial(userData?.savedKeywords || []);

              // setPageError(false);
              // setPreferenceUpdatedLoading(true);
              // setCurrentPage(0);
              // getUserFirestoreData();
              // setIsFetchingSummary(true);
              // fetchFeedData({ page: 0 });
              // setIsTrendingFeedFetchedOnServerState(false);
              // if (isTrending) {
              //   setIsFetchingDiscoverMoreTags(true);
              //   fetchDiscoverMoreTags();
              // } else {
              //   setIsFetchingTrendingTopics(true);
              //   fetchTrendingTopics();
              // }
            }}
            suggestedPreferences={suggestedPreferences}
            handleOnBackFromCustomizeYourFeed={(
              currentFollowingPreferences,
              currentSuggestedPreferences,
              currentSaveKeywords
            ) => {
              const updatedSavedKeywordTemp = currentSaveKeywords.map((el) => {
                if (el.isSelected) {
                  return el.label;
                }
              });

              const followingFromSuggestionTemp = [];
              currentSuggestedPreferences.map((el) => {
                el.tags.map((tag) => {
                  if (tag.isSelected) followingFromSuggestionTemp.push(tag);
                });
              });
              // Only compare id and isSelected properties
              const tempCurrentFollowingPreferences = currentFollowingPreferences.map((i) => ({
                id: i.id,
                isSelected: i.isSelected
              }));
              const tempFollowingPreferences = followingPreferences.map((i) => ({
                id: i.id,
                isSelected: i.isSelected
              }));
              const tempFollowingFromSuggestionTemp = followingFromSuggestionTemp.map((i) => ({
                id: i.id,
                isSelected: i.isSelected
              }));

              if (
                JSON.stringify(tempCurrentFollowingPreferences) !==
                  JSON.stringify(tempFollowingPreferences) ||
                Object.entries(savedKeywords).toString() !==
                  Object.entries(updatedSavedKeywordTemp).toString() ||
                JSON.stringify(tempCurrentFollowingPreferences) !==
                  JSON.stringify(tempFollowingFromSuggestionTemp)
              ) {
                setPreferenceUpdatedLoading(true);
                setShowMyFeedsToast(true);
                setLoadToast(true);
                const temp = [
                  ...new Set([...currentFollowingPreferences, ...followingFromSuggestionTemp])
                ];
                setFollowingPreferences(temp);

                setSuggestedPreferences(currentSuggestedPreferences);

                if (
                  currentFollowingPreferences.length > 0 ||
                  followingFromSuggestionTemp.length > 0 ||
                  updatedSavedKeywordTemp.filter(function (element) {
                    return element !== undefined;
                  })?.length > 0
                ) {
                  setIsFetchingTrendingFeeds(true);
                  fetchTrendingAricles();
                  setIsFetchingSummary(true);
                  setRecent(true);
                  fetchFeedData({ page: 0, isRecent: true });
                  setIsTrendingFeedFetchedOnServerState(false);
                  if (isTrending) {
                    setIsFetchingDiscoverMoreTags(true);
                    fetchDiscoverMoreTags();
                  } else {
                    setIsFetchingTrendingTopics(true);
                    fetchTrendingTopics();
                  }

                  updateReturnFromCustomizeYourFeed();
                } else {
                  scrollToTop();

                  if (!isTrending) {
                    router.push("/");
                  } else {
                    setIsFetchingSummary(true);
                    setRecent(true);
                    fetchFeedData({ page: 0, isRecent: true });
                    setIsTrendingFeedFetchedOnServerState(false);
                    if (isTrending) {
                      setIsFetchingDiscoverMoreTags(true);
                      fetchDiscoverMoreTags();
                    } else {
                      setIsFetchingTrendingTopics(true);
                      fetchTrendingTopics();
                    }

                    updateReturnFromCustomizeYourFeed();
                  }
                }
                // setIsFetchingSummary(true);
                // setRecent(true);
                // fetchFeedData({ page: 0, isRecent: true });
                // setIsTrendingFeedFetchedOnServerState(false);
                // if (isTrending) {
                //   setIsFetchingDiscoverMoreTags(true);
                //   fetchDiscoverMoreTags();
                // } else {
                //   setIsFetchingTrendingTopics(true);
                //   fetchTrendingTopics();
                // }

                // updateReturnFromCustomizeYourFeed();
                scrollToTop();
              } else {
                setFollowingPreferences(followingFromSuggestionTemp);
              }
              setStartPolling(true);
            }}
            stopPolling={() => setStartPolling(false)}
          />
          {preferenceUpdatedLoading ? (
            <div className="h-screen w-full overflow-hidden rounded-md">
              {/* <Header/> */}
              {/* web stories loader */}
              {/* <div className="no-scrollbar flex flex-col overflow-scroll">
                <div className="mt-3 mb-1 flex w-full items-center justify-between px-4">
                  <p className="h-[24px] w-full w-[84px] animate-pulse rounded-lg bg-[#80808036]  object-cover" />

                  <p className="h-[26px] w-full w-[46px] animate-pulse rounded-lg bg-[#80808036]  object-cover" />
                </div>
                <div className="flex pl-4">
                  {Array(5)
                    .fill(0)
                    .map((el, index) => {
                      return (
                        <div className="mr-2 w-[84px]" key={index}>
                          <p className="h-[84px] w-full w-[84px] animate-pulse rounded-[49%] bg-[#80808036]  object-cover" />
                          <p className="mt-[8px] h-[32px] w-full w-[84px] animate-pulse rounded-lg bg-[#80808036]  object-cover" />
                        </div>
                      );
                    })}
                </div>
              </div> */}
              <div
                className={
                  Object.fromEntries(new URLSearchParams(window?.location?.search).entries())
                    .mode !== "naukri" && !pathname.split("/").includes("naukri")
                    ? "mt-9 flex-1 px-5"
                    : "flex-1 px-5"
                }
              >
                <div
                  className={cx(
                    Object.fromEntries(new URLSearchParams(window?.location?.search).entries())
                      .mode !== "naukri" && !pathname.split("/").includes("naukri")
                      ? ""
                      : "mt-6",
                    "h-6 w-48 rounded bg-[#80808036]"
                  )}
                ></div>

                {/* {Object.fromEntries(
                new URLSearchParams(window?.location?.search).entries()
              ).mode !== "naukri" && !pathname.split("/").includes("naukri") ? (
                <div className="w-[494px]">
                  <div className="mt-6 flex">
                    {[1, 2, 3, 4, 5].map((el) => (
                      <div
                        className="mr-4 h-20 w-20 rounded-full bg-[#80808036]"
                        key={el}
                      />
                    ))}
                  </div>
                </div>
              ) : null} */}

                <div
                  className={
                    Object.fromEntries(new URLSearchParams(window?.location?.search).entries())
                      .mode !== "naukri" && !pathname.split("/").includes("naukri")
                      ? "mt-12"
                      : ""
                  }
                >
                  {Array(2)
                    .fill(0)
                    .map((el, index) => (
                      <div className="mt-6 py-3" key={index}>
                        <div className=" h-5 w-28 rounded bg-[#80808036]"></div>
                        <div className=" mt-4 h-40 w-full rounded bg-[#80808036]"></div>
                        <div className=" mt-4 h-40 w-full rounded bg-[#80808036]"></div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <>
              <>
                {isNewVisible && !hideScrollToTop && (
                  <div
                    className={cx(
                      "absolute z-[300] flex w-full cursor-pointer justify-center pt-8 xs:w-[480px]"
                    )}
                  >
                    <div
                      className={
                        "z-[30] rounded-[98px] bg-N100 px-4 py-2 text-readingTextMedium font-semiBold leading-readingTextMedium text-P500 shadow-l dark:bg-DN100 dark:text-P500 dark:shadow-dl"
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        clickhouse.trackNewArticleChickletClick({
                          element_name: "new_articles_chicklet"
                        });
                        const userData = JSON.parse(localStorage.getItem("userData"));
                        const prefs = JSON.parse(localStorage.getItem("prefs"));
                        const selectedPreferences = [];
                        prefs.map((category) => {
                          category.tags.map((tag) => {
                            if (userData?.preferences?.includes(tag.id)) {
                              tag.isSelected = true;
                              selectedPreferences.push(tag);
                            }
                          });
                        });

                        setFollowingPreferences(selectedPreferences);
                        setSavedKeywordInitial(userData?.savedKeywords || []);
                        setPageError(false);
                        setPreferenceUpdatedLoading(true);
                        setCurrentPage(0);
                        getUserFirestoreData();
                        setIsFetchingSummary(true);
                        setRecent(true);
                        updateReturnFromCustomizeYourFeed();
                        setShowOlderContent(false);
                        fetchFeedData({ page: 0, isRecent: true });
                        setNewVisible(false);
                        setStartPolling(false);
                        setRecent(true);
                        setIsTrendingFeedFetchedOnServerState(false);
                        if (isTrending) {
                          setIsFetchingDiscoverMoreTags(true);
                          fetchDiscoverMoreTags();
                        } else {
                          setIsFetchingTrendingTopics(true);
                          fetchTrendingTopics();
                        }

                        setIsFetchingTrendingFeeds(true);
                        fetchTrendingAricles();
                        scrollToTop();
                      }}
                    >
                      New Articles
                    </div>
                  </div>
                )}
              </>

              {/* {isGoogleWebStoriesLoading && (
                <div className="no-scrollbar mb-3 flex flex-col overflow-scroll">
                  <div className="mt-3 mb-1 flex w-full items-center justify-between px-4">
                    <p className="h-[24px] w-full w-[84px] animate-pulse rounded-lg bg-[#80808036]  object-cover" />

                    <p className="h-[26px] w-full w-[46px] animate-pulse rounded-lg bg-[#80808036]  object-cover" />
                  </div>
                  <div className="flex pl-4">
                    {Array(5)
                      .fill(0)
                      .map((el, index) => {
                        return (
                          <div className="mr-2 w-[84px]" key={index}>
                            <p className="h-[84px] w-full w-[84px] animate-pulse rounded-[49%] bg-[#80808036]  object-cover" />
                            <p className="mt-[8px] h-[32px] w-full w-[84px] animate-pulse rounded-lg bg-[#80808036]  object-cover" />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )} */}
              {/* <div></div>
              {googleWebStoriesDetails.length && !isGoogleWebStoriesLoading ? (
                <div className="relative">
                  <div className="mt-3 flex w-full items-center justify-between px-4">
                    <p className="text-readingTextLarge font-bold leading-readingTextLarge text-N900 dark:text-N100">
                      Stories
                    </p>
                    {naukriEducation === 0 ? (
                      <Link
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}/web-stories-categories`}
                        passHref
                        prefetch={false}
                      >
                        <a>
                          <button className="text-readingTextSmall font-bold leading-readingTextSmall text-[#F73D38]">
                            View All
                          </button>
                        </a>
                      </Link>
                    ) : (
                      <p>
                        <button className="text-readingTextSmall font-bold leading-readingTextSmall text-[#F73D38]">
                          View All
                        </button>
                      </p>
                    )}
                  </div>

                  {mounted && (
                    <div>
                      <StoriesWidget
                        stories={googleWebStoriesDetails}
                        newswrapData={newswrapData}
                        setHash={setHash}
                        disable={naukriEducation > 0}
                        setShowPlayerLoading={setShowPlayerLoading}
                        setIsStoryWidgetClicked={setIsGoogleStoryWidgetClicked}
                        isStoryWidgetClicked={isGoogleStoryWidgetClicked}
                        getStoryDetail={getStoryDetail}
                        handleOnUpdateNewswrapData={(obj) => {
                          if (obj.id === newswrapData.id) {
                            let temp = { ...newswrapData };
                            temp = obj;
                            setNewswrapData(temp);
                          }
                        }}
                        showNewswrap={() => {
                          setShowNewswrap(true);
                          setMutedNewswrap(false);
                          setTimeout(() => {
                            newswrapVidRef?.current?.play();
                          }, 200);

                          clickhouse.trackStoryView({
                            element_name: `circular_newswrap_story_widget`,
                            element_type: "story",
                            actionType: "view",
                            eventCategory: "story_view",
                            data: {
                              title: newswrapData.title
                            }
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : null}

              {googleWebStoriesDetails.length && isGoogleStoryWidgetClicked ? (
                <StoryPlayer
                  stories={googleWebStoriesDetails}
                  isCircularWidget={true}
                  handleOnClosedPlayer={() => {
                    storyPage = 0;
                    // router.back();
                    window["seenStoryList"] = JSON.stringify([]);
                    setShowPlayerLoading(false);
                    window["storyWidget"] = null;
                    if (
                      navigator.userAgent.includes("iPhone") ||
                      navigator.userAgent.includes("iPad")
                    ) {
                      setIsGoogleStoryWidgetClicked(false);
                    }
                  }}
                />
              ) : null} */}

              {!!!data?.length && (
                <div
                  className="flex flex-col items-center justify-center"
                  // style={{
                  //   height: "calc(100vh - 74px)"
                  // }}
                >
                  <EmptyStateView
                    type="noFeed"
                    onClick={() => {
                      router.reload();
                    }}
                    mounted={mounted}
                  />

                  {trendingTopics.length ? (
                    <div className="flex h-full w-full cursor-pointer flex-col justify-between overflow-hidden border-b-[4px] border-P100 bg-N100 px-5 duration-300 dark:border-DG700 dark:bg-DN050">
                      <DiscoverMoreWidget
                        title={isTrending ? "Trending Topics" : "Discover More"}
                        discoverMoreTags={trendingTopics || []}
                        observer={observer}
                      />
                    </div>
                  ) : null}
                </div>
              )}
              {!!data?.length && (
                <div>
                  <div
                    className={cx(
                      "z-10 flex w-full flex-col items-start bg-N100 px-5 pt-5 dark:bg-DN050"
                    )}
                  >
                    <div className="flex w-full justify-between bg-N100 transition-none dark:bg-DN050">
                      <SectionHeading>
                        {isTrending ? "Here’s what’s trending" : `Picked for you`}
                      </SectionHeading>
                    </div>
                  </div>
                  {/* {showPopup ? (
                    <Popup
                      handleOnClick={() => {
                        setShowPopup(false);
                        container.classList.remove("disable-scroll");
                      }}
                    />
                  ) : null} */}
                  <div
                    className="mx-auto my-0 bg-N100 dark:bg-DN050"
                    // style={{
                    //   height: "calc(100vh - 144px)",
                    // }}
                  >
                    {/* {showPlayerLoading && (
                      <div className="absolute top-0 left-0 z-[750] flex h-screen w-screen flex-col items-center justify-center bg-[#00000096]">
                        <img
                          src={ImgClose}
                          alt="close"
                          width="44px"
                          height="44px"
                          className="absolute top-[44px] z-[700] h-[44px] w-[44px]"
                          onClick={() => {
                            closeStoryPlayer();
                          }}
                        />
                        <div className={s.circles}>
                          <div className={s.circle1}></div>
                          <div className={s.circle2}></div>
                          <div className={s.circle3}></div>
                        </div>
                        <p className="font-bold text-N100">Loading...</p>
                      </div>
                    )} */}

                    {/* {stories.length && isStoryWidgetClicked ? (
                      <StoryPlayer
                        stories={storiesData}
                        isCircularWidget={false}
                        handleOnClosedPlayer={() => {
                          storyPage = 0;
                          // router.back();
                          window["seenStoryList"] = JSON.stringify([]);
                          setShowPlayerLoading(false);
                          window["storyWidget"] = null;
                          if (
                            navigator.userAgent.includes("iPhone") ||
                            navigator.userAgent.includes("iPad")
                          ) {
                            setIsStoryWidgetClicked(false);
                          }
                        }}
                      />
                    ) : null} */}

                    {data.map((articleData: any, index: number) => {
                      if (typeof articleData === "string" && widgets.includes(articleData)) {
                        showArticleCard = false;
                      } else {
                        articleCount++;
                        showArticleCard = true;
                      }
                      return (
                        <div
                          key={index}
                          ref={
                            index === 0
                              ? firstElementObserve
                              : index === data.length - 1
                              ? observe
                              : null
                          }
                        >
                          {articleData === "bigshyftBanner" && (
                              <BigShyftPitchCard  />
                            )}
                          {articleData === "wordCoach" &&
                            userPuzzles.length < 16 &&
                            puzzles.length > 0 &&
                            typeof window !== "undefined" && (
                              <WordCoachWidget observer={observer} puzzles={puzzles} />
                            )}
                          {articleData === "trending" && trendingArticles.length > 0 && (
                            <TrendingArticles trendingArticleList={trendingArticles} />
                          )}

                          {articleData === "download" && (
                            <DownloadAppWidget
                              observer={observer}
                              title="Get full access to all the trending tech news"
                            />
                          )}
                          {articleData === "subscribe" && !isSignUp && (
                            <SignMeUpWidget isSuccessMode={isSignUp} observer={observer} />
                          )}
                          {articleData === "discoverMoreWithTags" && discoverMoreTags.length && (
                            <div className="flex h-full w-full cursor-pointer flex-col justify-between overflow-hidden border-b border-P100 bg-N100 px-5 duration-300 dark:border-DG700 dark:bg-DN050">
                              <DiscoverMoreWithTagsWidget
                                discoverMoreTags={discoverMoreTags || []}
                                updatePreferences={updateTagsPreferencesWithDiscoverMoreWidget}
                                discoverMoreChips={discoverMoreChips}
                                setDiscoverMoreChips={setDiscoverMoreChips}
                                stopFeedPolling={() => setStartPolling(false)}
                                resumeFeedPolling={() => setStartPolling(true)}
                                observer={observer}
                                mounted={mounted}
                              />
                            </div>
                          )}

                          {/* {["story1", "story2", "story3", "story4", "story5"].map(
                            (el: any, index) => {
                              if (articleData === el && stories.length) {
                                return (
                                  <StoryWidget
                                    key={`storyWidget${index + 1}`}
                                    stories={stories}
                                    index={index}
                                    setHash={setHash}
                                    setStoriesData={setStoriesData}
                                    setShowPlayerLoading={setShowPlayerLoading}
                                    setIsStoryWidgetClicked={setIsStoryWidgetClicked}
                                    isStoryWidgetClicked={isStoryWidgetClicked}
                                    getStoryDetail={getStoryDetail}
                                  />
                                );
                              }
                            }
                          )} */}

                          {articleData === "trendingTopics" && trendingTopics.length && (
                            <div className="flex h-full w-full cursor-pointer flex-col justify-between overflow-hidden border-b border-P100 bg-N100 px-5 duration-300 dark:border-DG700 dark:bg-DN050">
                              <DiscoverMoreWidget
                                title="Trending Topics"
                                discoverMoreTags={trendingTopics || []}
                                observer={observer}
                              />
                            </div>
                          )}
                          {/* {articleData === "secondaryDiscoverMore" &&
                            secondaryDiscoverMoreTags.length && (
                              <div className="flex h-full w-full cursor-pointer flex-col justify-between overflow-hidden border-b border-P100 bg-N100 px-5 duration-300 dark:border-DG700 dark:bg-DN050">
                                <DiscoverMoreWidget
                                  title="You might also be interested in"
                                  discoverMoreTags={secondaryDiscoverMoreTags || []}
                                  observer={observer}
                                />
                              </div>
                            )} */}

                          {articleData.newsBiteType == "NEWS_WRAP" && index !== 0 && (
                            <VideoArticle
                              key={index}
                              index={index}
                              articleData={articleData}
                              userLikeArticles={userLikeArticles}
                              className="mb-1 mt-5"
                              googleWebStories={hash}
                            />
                          )}
                          {showArticleCard && articleData.newsBiteType == "SHORT_ARTICLE" && (
                            <>
                              {index % 7 === 0 ? (
                                <NewArticleCard
                                  index={index}
                                  className="flex h-full w-full cursor-pointer flex-col justify-between border-b-[3px] border-P100 bg-N100 pt-5 duration-300 dark:border-DG700 dark:bg-DN050"
                                  closeDownloadAppDrawer={closeDownloadAppDrawer}
                                  key={articleData.id}
                                  titleClickable={naukriEducation === 0}
                                  isTrending={isTrending}
                                  articleData={articleData}
                                  removeNotInterestedArticle={removeNotInterestedArticle}
                                  userLikeArticles={userLikeArticles}
                                  hideFollowOptionFromThreeDotDrawer={
                                    (followingPreferences.length === 0 &&
                                      savedKeywordInitial.length === 0) ||
                                    isTrending
                                  }
                                  handleOnRead={(id) => {
                                    const readArticleListTemp = [...readArticleList];
                                    const set = new Set(readArticleListTemp);
                                    set.add(id);
                                    const temp = [...set];
                                    setReadArticleList(temp);
                                  }}
                                  userBookmarkArticles={userBookmarkArticles}
                                  // isSourceHidden
                                  savedKeyword={value}
                                  toggleFollowBtn={() => {}}
                                  setRemoveCount={setRemoveCount}
                                  removeCount={removeCount}
                                  showFullDescription={true}
                                  preferences={preferenceIds}
                                  setPreferences={setPreferenceIds}
                                  updateSavedKeywords={updateSavedKeywords}
                                  updatePreferences={updatePreferences}
                                  isFollowing={getFollowingStatus(articleData)}
                                  observer={observer}
                                  lazyLoadImage={articleCount <= 2 ? false : true}
                                  imageObserver={imageObserver}
                                  articleSeen={articleData.id === seenArticle}
                                  articleRead={articleData.id === readArticle}
                                  seenArticleList={seenArticleList}
                                />
                              ) : (
                                <SmallArticleCard
                                  index={index}
                                  className="flex h-full w-full cursor-pointer flex-col justify-between border-b-[3px] border-P100 bg-N100 pt-5 duration-300 dark:border-DG700 dark:bg-DN050"
                                  closeDownloadAppDrawer={closeDownloadAppDrawer}
                                  key={articleData.id}
                                  titleClickable={naukriEducation === 0}
                                  isTrending={isTrending}
                                  articleData={articleData}
                                  removeNotInterestedArticle={removeNotInterestedArticle}
                                  userLikeArticles={userLikeArticles}
                                  hideFollowOptionFromThreeDotDrawer={
                                    (followingPreferences.length === 0 &&
                                      savedKeywordInitial.length === 0) ||
                                    isTrending
                                  }
                                  userBookmarkArticles={userBookmarkArticles}
                                  // isSourceHidden
                                  savedKeyword={value}
                                  toggleFollowBtn={() => {}}
                                  setRemoveCount={setRemoveCount}
                                  removeCount={removeCount}
                                  showFullDescription={true}
                                  preferences={preferenceIds}
                                  setPreferences={setPreferenceIds}
                                  handleOnRead={(id) => {
                                    const readArticleListTemp = [...readArticleList];
                                    const set = new Set(readArticleListTemp);
                                    set.add(id);
                                    const temp = [...set];
                                    setReadArticleList(temp);
                                  }}
                                  updateSavedKeywords={updateSavedKeywords}
                                  updatePreferences={updatePreferences}
                                  isFollowing={getFollowingStatus(articleData)}
                                  observer={observer}
                                  lazyLoadImage={articleCount <= 2 ? false : true}
                                  imageObserver={imageObserver}
                                  articleSeen={articleData.id === seenArticle}
                                  articleRead={articleData.id === readArticle}
                                  seenArticleList={seenArticleList}
                                />
                              )}
                            </>
                          )}

                          {/* {((index === 11 && !isTrending) || (isTrending && index === 10)) && (
                            <div ref={endOfContentObserver}>
                              <EndOfContent
                                type="DownloadApp"
                                fetchFeedData={() => {
                                  setIsMoreLoading(true);
                                  setShowOlderContent(true);
                                  setEndOfFeed(false);
                                  setIsTrendingFeedFetchedOnServerState(false);
                                  fetchFeedData({
                                    page: 0,
                                    showOlderContent: true
                                  });
                                }}
                              />
                            </div>
                          )} */}
                        </div>
                      );
                    })}
                    {/* {showEndOfContentDownloadAppCard && !isMoreLoading && (
                      <div ref={endOfContentObserver}>
                        <DownloadApp />
                      </div>
                    )} */}
                    {/* DOWNLOAD APP POPUP */}
                    {/* {showDownloadAppPopup && !showFeedbackPopup && (
                      <DownloadAppPopup
                        observer={observer}
                        handleOnDownload={() => {
                          setShowDownloadAppPopup(false);
                          const container =
                            document.getElementById("unsupported-browser-container") ||
                            document.getElementById("container");
                          container.classList.remove("disable-scroll");
                        }}
                        onClose={() => {
                          setShowDownloadAppPopup(false);
                          setShowGlobalDownloadAppPopup(false);
                          if (window.globalAppPopupTimer) {
                            clearTimeout(window.globalAppPopupTimer);
                            window.globalAppPopupTimer = null;
                          }

                          const container =
                            document.getElementById("unsupported-browser-container") ||
                            document.getElementById("container");
                          container.classList.remove("disable-scroll");
                        }}
                      />
                    )} */}

                    {showOlderContentCard && (
                      <div className="flex h-full w-full flex-col items-center justify-center">
                        <EndOfContent
                          hasOlderContent={showOlderContentCard}
                          fetchFeedData={() => {
                            setIsMoreLoading(true);
                            setShowOlderContent(true);
                            setEndOfFeed(false);
                            setRecent(false);
                            setIsTrendingFeedFetchedOnServerState(false);
                            fetchFeedData({
                              page: 0,
                              showOlderContent: true,
                              isRecent: false
                            });
                          }}
                        />
                      </div>
                    )}
                    {showEndOfContentCard && (
                      <div
                        className="flex h-full w-full flex-col items-center justify-center"
                        ref={endOfContentObserver}
                      >
                        <EndOfContent hasOlderContent={false} />
                      </div>
                    )}

                    {isFetchingFeedError && !isMoreLoading ? (
                      <ErrorEndOfContent
                        fetchFeedData={() => {
                          setIsFetchingFeedError(false);
                          setIsMoreLoading(true);
                          fetchMoreData();
                        }}
                        hasOlderContent={true}
                      />
                    ) : null}
                  </div>
                </div>
              )}
              {isMoreLoading ? (
                <div className="mt-6 px-5">
                  <div className="h-40 w-full animate-pulse rounded bg-N200  dark:bg-DN200"></div>
                  <div className=" mt-4 h-40 w-full animate-pulse  rounded bg-N200 dark:bg-DN200"></div>
                </div>
              ) : null}
            </>
          )}
        </>
      )}
      {!preferenceUpdatedLoading ? (
        <div className="pos_right fixed bottom-9 z-[700] flex">
          <div>
            {isTrending ? (
              showMyFeedsButton ? (
                <>
                  <button
                    className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-[#F94929] p-3 shadow-customBottom shadow-[#0204092e]"
                    onClick={() => {
                      // const userData = JSON.parse(localStorage.getItem("userData"));
                      clickhouse.trackCTAs({
                        element_name: "myfeeds_floating_button",
                        element_type: "floating_button",
                        eventCategory: "click"
                      });
                      // const prefs = JSON.parse(localStorage.getItem("prefs"));
                      // const selectedPreferences = [];
                      // prefs.map((category) => {
                      //   category.tags.map((tag) => {
                      //     if (userData?.preferences?.includes(tag.id)) {
                      //       tag.isSelected = true;
                      //       selectedPreferences.push(tag);
                      //     }
                      //   });
                      // });

                      // setFollowingPreferences(selectedPreferences);
                      // setSavedKeywordInitial(userData?.savedKeywords || []);
                      // setIsTrending(false);
                      scrollToTop();
                      // localStorage.setItem("feedPage", "myFeed");
                      // if (isNaukriUser) {
                      //   router.push(`/naukri/my-feeds${window.location.search}`);
                      // } else {
                      router.push("/my-feeds");
                      // }
                      // setPageError(false);
                      // setPreferenceUpdatedLoading(true);
                      // setCurrentPage(0);
                      // getUserFirestoreData();
                      // setIsFetchingSummary(true);
                      // fetchFeedData({ page: 0 });
                      // setIsTrendingFeedFetchedOnServerState(false);
                      // if (isTrending) {
                      //   setIsFetchingDiscoverMoreTags(true);
                      //   fetchDiscoverMoreTags();
                      // } else {
                      //   setIsFetchingTrendingTopics(true);
                      //   fetchTrendingTopics();
                      // }

                      // setIsFetchingTrendingFeeds(true);
                      // fetchTrendingAricles();
                    }}
                  >
                    <img src={FeedsWhiteImg} alt="feed" />
                  </button>

                  {loadToast && (
                    <Toast
                      mounted={mounted}
                      show={showMyFeedsToast}
                      onClose={() => {
                        setShowMyFeedsToast(false);
                        clickhouse.trackCTAs({
                          element_name: "cross_icon",
                          element_type: "icon",
                          eventCategory: "click",
                          pageSection: "switch_to_myfeeds_toastbar"
                        });
                      }}
                      content="Preferences Updated"
                      buttonText="Go to my feeds"
                      buttonClassName="mr-3 text-readingTextMedium font-semiBold leading-readingTextMedium text-P500"
                      onClick={() => {
                        // localStorage.setItem("feedPage", "myFeed");
                        scrollToTop();
                        // if (isNaukriUser) {
                        //   router.push(`/naukri/my-feeds${window.location.search}`);
                        // } else {
                        router.push("/my-feeds");
                        // }
                        // setIsTrending(false);
                        // clickhouse.trackCTAs({
                        //   element_name: "go_to_myfeeds_link",
                        //   element_type: "link",
                        //   eventCategory: "click",
                        //   pageSection: "switch_to_myfeeds_toastbar"
                        // });
                        // const userData = JSON.parse(localStorage.getItem("userData"));
                        // const prefs = JSON.parse(localStorage.getItem("prefs"));
                        // const selectedPreferences = [];
                        // prefs.map((category) => {
                        //   category.tags.map((tag) => {
                        //     if (userData?.preferences?.includes(tag.id)) {
                        //       tag.isSelected = true;
                        //       selectedPreferences.push(tag);
                        //     }
                        //   });
                        // });

                        // setFollowingPreferences(selectedPreferences);
                        // setSavedKeywordInitial(userData?.savedKeywords || []);

                        // setPageError(false);
                        // setPreferenceUpdatedLoading(true);
                        // setCurrentPage(0);
                        // getUserFirestoreData();
                        // setIsFetchingSummary(true);
                        // fetchFeedData({ page: 0 });
                        // setIsTrendingFeedFetchedOnServerState(false);
                        // if (isTrending) {
                        //   setIsFetchingDiscoverMoreTags(true);
                        //   fetchDiscoverMoreTags();
                        // } else {
                        //   setIsFetchingTrendingTopics(true);
                        //   fetchTrendingTopics();
                        // }

                        // setIsFetchingTrendingFeeds(true);
                        // fetchTrendingAricles();
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  {/* <button
                    className="flex h-[49px] w-[49px] items-center justify-center rounded-full bg-P500 p-3 shadow-customBottom shadow-[#0204092e]"
                    onClick={() => {
                      setStartPolling(false);
                      setOpenCustomizedFeed(true);
                      disableScroll(true);
                    }}
                  >
                    <img src={FilterDark} alt="icon" />
                  </button> */}
                  {loadToast && (
                    <Toast
                      mounted={mounted}
                      show={showMyFeedsToast}
                      onClose={() => {
                        setShowMyFeedsToast(false);
                        clickhouse.trackCTAs({
                          element_name: "cross_icon",
                          element_type: "icon",
                          eventCategory: "click",
                          pageSection: "switch_to_myfeeds_toastbar"
                        });
                      }}
                      content="Preferences Updated"
                    />
                  )}
                </>
              )
            ) : (
              <button
                className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-[#F94929] p-3 shadow-customBottom shadow-[#0204092e]"
                onClick={() => {
                  // const userData = JSON.parse(localStorage.getItem("userData"));
                  clickhouse.trackCTAs({
                    element_name: "trending_floating_button",
                    element_type: "floating_button",
                    eventCategory: "click"
                  });
                  // const prefs = JSON.parse(localStorage.getItem("prefs"));
                  // const selectedPreferences = [];
                  // prefs.map((category) => {
                  //   category.tags.map((tag) => {
                  //     if (userData?.preferences?.includes(tag.id)) {
                  //       tag.isSelected = true;
                  //       selectedPreferences.push(tag);
                  //     }
                  //   });
                  // });

                  // setFollowingPreferences(selectedPreferences);
                  // setSavedKeywordInitial(userData?.savedKeywords || []);
                  // localStorage.setItem("feedPage", "isTrending");
                  // setIsTrending(true);

                  // setPageError(false);

                  // scrollToTop();
                  // setPreferenceUpdatedLoading(true);
                  // setCurrentPage(0);
                  // getUserFirestoreData();
                  // setIsFetchingSummary(true);
                  // fetchFeedData({ page: 0 });
                  // setIsTrendingFeedFetchedOnServerState(false);
                  // if (isTrending) {
                  //   setIsFetchingDiscoverMoreTags(true);
                  //   fetchDiscoverMoreTags();
                  // } else {
                  //   setIsFetchingTrendingTopics(true);
                  //   fetchTrendingTopics();
                  // }
                  scrollToTop();
                  // if (isNaukriUser) {
                  //   router.push(`/naukri${window.location.search}`);
                  // } else {
                  if (!isTrending) {
                    router.push("/");
                  }
                  // }
                  // setIsFetchingTrendingFeeds(true);
                  // fetchTrendingAricles();
                }}
              >
                <img src={TrendingImg} alt="trending" />
              </button>
            )}
          </div>
          {/* {!hideScrollToTop ? (
            <button
              className=" ml-2 flex h-[49px] w-[49px] items-center justify-center rounded-full bg-P500 p-3 shadow-customBottom shadow-[#0204092e]"
              onClick={() => scrollToTop()}
            >
              <img
                src={leftArrowWhiteImg}
                className="text-white h-[22px] w-[22px] rotate-90"
                alt="scroll top"
              />
            </button>
          ) : null} */}
        </div>
      ) : null}
      {openCustomizedFeed && (
        <CustomizeYourFeed
          open={openCustomizedFeed}
          suggestedPreferences={suggestedPreferences}
          followingPreferences={followingPreferencesState}
          savedKeywords={savedKeywords}
          onClose={() => {
            setOpenCustomizedFeed(!openCustomizedFeed);
            disableScroll(!openCustomizedFeed);
          }}
          handleOnBack={(
            currentFollowingPreferences,
            currentSuggestedPreferences,
            currentSaveKeywords
          ) => {
            const updatedSavedKeywordTemp = currentSaveKeywords.map((el) => {
              if (el.isSelected) {
                return el.label;
              }
            });

            const followingFromSuggestionTemp = [];
            currentSuggestedPreferences.map((el) => {
              el.tags.map((tag) => {
                if (tag.isSelected) followingFromSuggestionTemp.push(tag);
              });
            });

            // Only compare id and isSelected properties
            const tempCurrentFollowingPreferences = currentFollowingPreferences.map((i) => ({
              id: i.id,
              isSelected: i.isSelected
            }));
            const tempFollowingPreferences = followingPreferences.map((i) => ({
              id: i.id,
              isSelected: i.isSelected
            }));

            if (
              JSON.stringify(tempCurrentFollowingPreferences) !==
                JSON.stringify(tempFollowingPreferences) ||
              Object.entries(savedKeywords).toString() !==
                Object.entries(updatedSavedKeywordTemp).toString() ||
              followingFromSuggestionTemp.length
            ) {
              setPreferenceUpdatedLoading(true);
              setShowMyFeedsToast(true);
              setFollowingPreferences(followingFromSuggestionTemp);
              setSuggestedPreferences(currentSuggestedPreferences);
              if (
                currentFollowingPreferences.length > 0 ||
                updatedSavedKeywordTemp.filter(function (element) {
                  return element !== undefined;
                })?.length > 0
              ) {
                setIsFetchingTrendingFeeds(true);
                fetchTrendingAricles();
                setIsTrending(false);
                scrollToTop();
                // if (isNaukriUser) {
                //   router.push(`/naukri/my-feeds`);
                // } else {
                router.push("/my-feeds");
                // }
              } else {
                setIsTrending(true);
                // localStorage.setItem("feedPage", "isTrending");
                scrollToTop();
                // if (isNaukriUser) {
                //   router.push(`/naukri/`);
                // } else {
                if (!isTrending) {
                  router.push("/");
                }
                // }
              }
              setIsFetchingSummary(true);
              fetchFeedData({ page: 0, isRecent: true });
              setRecent(true);
              setIsTrendingFeedFetchedOnServerState(false);
              if (isTrending) {
                setIsFetchingDiscoverMoreTags(true);
                fetchDiscoverMoreTags();
              } else {
                setIsFetchingTrendingTopics(true);
                fetchTrendingTopics();
              }

              updateReturnFromCustomizeYourFeed();
            }
            setStartPolling(true);
            setOpenCustomizedFeed(false);
            disableScroll(!openCustomizedFeed);
          }}
          title="Customize your feed"
        />
      )}
      {/* GLOBAL DOWNLOAD APP POPUP */}
      {/* {showGlobalDownloadAppPopup && !showDownloadAppPopup && !showFeedbackPopup && (
        <GlobalDownloadAppPopup
          closePopup={() => {
            setShowDownloadAppPopup(false);
            setShowGlobalDownloadAppPopup(false);
            //@ts-ignore
            window.__popup = false;
            const container =
              document.getElementById("unsupported-browser-container") ||
              document.getElementById("container");
            container.classList.remove("disable-scroll");
          }}
        />
      )} */}
      {/* FEEDBACK MODAL */}
      {showFeedbackPopup && naukriEducation === 0 && (
        <FeedbackModal
          onClose={() => {
            setShowFeedbackPopup(false);
            window.__popup = false;
            const container =
              document.getElementById("unsupported-browser-container") ||
              document.getElementById("container");
            container.classList.remove("disable-scroll");
            clearTimeout(window.globalAppPopupTimer);
            clearTimeout(window.globalfeedbackPopup);
          }}
        />
      )}
    </>
  );
};

export default Feed;
