

import { config } from "./config";

import PitchCard from "./components/PitchCard";
import SectionHeading from "@components/ui-components/SectionHeading";

import * as clickhouse from "./ch-s-events";

const BigShyftPitchCard = () => {
  
  const handleExploreClick = (index: number) => {
    const sectionNo = Number(index)+1;
    clickhouse.trackCTAs({
      element_name: `explore_bigshyft_${sectionNo}`,
    });
    window.open(`${config[index].redirectionUrl}${sectionNo}`, "_blank");
  };

  return (
    <div className="bg-[#F3F8FF] dark:bg-transparent py-5 mt-5">
      <SectionHeading className="px-5 mb-5">
        BigShyft: Premium Tech Jobs
      </SectionHeading>
      <div className="flex gap-2.5 max-w-full overflow-x-auto no-scrollbar px-5">
        {config.map((item,index) => (<PitchCard key={item.heading} data={item} onExploreClick={() =>handleExploreClick(index)} />))}
      </div>
    </div>
  );
};

export default BigShyftPitchCard;
