import { ReactNode } from "react";
import cx from "classnames";

type Props = {
  children: ReactNode | string;
  className?: string;
};

const SectionHeading = ({ children, className }: Props) => (
  <h1
    className={cx(
      "text-title leading-title font-bold text-N900 dark:text-N100",
      className
    )}
  >
    {children}
  </h1>
);

export default SectionHeading;
