import { trackCHEvents } from "@analytics/utils/trackEvents";
import { compact } from "@utils/fn-utils";
import { getPageName } from "@utils/getPageName";

export const trackArticleCardClick = ({ element_name, data }) => {
  let page = getPageName();
  const payload = compact({
    element_name,
    element_type: "card",
    action_type: "click",
    ...data
  });
  trackCHEvents(`${page}_page_${element_name}_click`, payload);
};

export const trackArticleSeen = ({ pageType, data }) => {
  // page =
  //   window.location.pathname.split("/") && window.location.pathname.split("/")[1] !== ""
  //     ? window.location.pathname.split("/")[1]
  //     : "feeds";
  let page = getPageName();
  const payload = compact({
    eventCategory: "article_view",
    screenName: pageType ? pageType : page,
    ...data
  });
  trackCHEvents(`${pageType ? pageType : page}_page_article_view`, payload);
};

export const trackCTAs = ({ element_name, data, screenName = "", pageType = undefined }) => {
  // let page = window.location.pathname.split("/")[1]
  //   ? window.location.pathname.split("/")[1]
  //   : screenName === "stories"
  //   ? "stories"
  //   : "feeds";
  let page = getPageName();

  const payload = compact({
    element_name,
    screenName: screenName || page,
    element_type: "link",
    action_type: "click",
    ...data
  });

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  trackCHEvents(`${pageType ? pageType : page}_page_${element_name}_click`, payload);
};

export const trackArticleFollowClick = ({ element_name, data }) => {
  const payload = compact({
    element_name,
    element_type: "link",
    action_type: "click",
    ...data
  });
  let page = getPageName();

  trackCHEvents(`${page}_page_${element_name}_click`, payload);
};

export const trackArticleUnfollowClick = ({ element_name, data }) => {
  const payload = compact({
    element_name,
    element_type: "link",
    action_type: "click",
    ...data
  });
  let page = getPageName();

  trackCHEvents(`${page}_page_${element_name}_click`, payload);
};

export const trackArticleNotInterestedSubmitClick = ({
  element_name,
  screenName = "",
  data,
  pageType = ""
}) => {
  // let page = window.location.pathname.split("/")
  //   ? window.location.pathname.split("/")[1]
  //   : screenName === "stories"
  //   ? "stories"
  //   : "trending";

  let page = getPageName();

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  const payload = compact({
    element_name,
    element_type: "button",
    action_type: "click",
    screenName: screenName ? screenName : page,
    ...data
  });

  trackCHEvents(`${pageType ? pageType : page}_page_${element_name}_click`, payload);
};

export const trackArticleReportClick = ({ element_name, data, screenName = "", pageType = "" }) => {
  // let page = window.location.pathname.split("/")[1]
  //   ? window.location.pathname.split("/")[1]
  //   : screenName === "stories"
  //   ? "stories"
  //   : "feeds";
  let page = getPageName();
  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  const payload = compact({
    element_name,
    element_type: "link",
    action_type: "click",
    screenName: screenName ? screenName : page,
    ...data
  });

  trackCHEvents(`${pageType ? pageType : page}_page_${element_name}_click`, payload);
};

export const trackArticleReportSubmitClick = ({
  element_name,
  data,
  screenName = "",
  pageType = ""
}) => {
  // let page = window.location.pathname.split("/")
  //   ? window.location.pathname.split("/")[1]
  //   : screenName === "stories"
  //   ? "stories"
  //   : "trending";

  let page = getPageName();

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  const payload = compact({
    element_name,
    element_type: "button",
    action_type: "click",
    screenName: screenName ? screenName : page,
    ...data
  });

  trackCHEvents(`${pageType ? pageType : page}_page_${element_name}_click`, payload);
};

export const trackArticleCardFooterClick = ({
  element_name,
  data,
  screenName = "",
  pageType = undefined
}) => {
  // let page = window.location.pathname.split("/")
  //   ? window.location.pathname.split("/")[1]
  //   : screenName === "stories"
  //   ? "stories"
  //   : "trending";

  let page = getPageName();

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  const payload = compact({
    element_name,
    element_type: "icon",
    action_type: "click",
    screenName: screenName ? screenName : page,
    ...data
  });

  trackCHEvents(`${pageType ? pageType : page}_page_${element_name}_click`, payload);
};
