import React, { ReactNode } from "react";
import cx from "classnames";
import { DEFAULT_VARIANT_CONFIG } from "./button.config";

type Props = {
  onClick: (_e) => void;
  children: ReactNode | string;
  className?: string;
  variant: "button" | "link";
  appearance?: "primary" | "secondary";
  size?: "regular" | "small" | "large";
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  disabled?: boolean;
};

type ElementProps = {
  stopPropagation: () => void;
};

const Button = ({
  children,
  onClick,
  className,
  variant = "button",
  appearance = "primary",
  size = "regular",
  disabled = false,
  width,
  height,
  padding,
  margin,
  ...restProps
}: Props) => {
  const onButtonClick = (e: ElementProps) => {
    e.stopPropagation();
    if (disabled) return;
    onClick(e);
  };

  const buttonProps = {
    ...restProps,
    className: cx(
      className,
      "box-border flex justify-center items-center cursor-pointer rounded font-semiBold",
      DEFAULT_VARIANT_CONFIG({ size }).button[appearance],
      `${disabled ? "bg-[#95B1F2] cursor-not-allowed pointer-events-none" : ""}`
    ),
    onClick: onButtonClick
  };

  return (
    <>
      {variant === "button" && (
        <button type="button" {...buttonProps} style={{ width, height, padding, margin }}>
          {children}
        </button>
      )}

      {variant === "link" && (
        <button
          type="button"
          {...restProps}
          onClick={onClick}
          className={cx(
            className,
            "font-semiBold text-P500",
            DEFAULT_VARIANT_CONFIG({ size }).link,
            `${disabled ? "pointer-events-none cursor-not-allowed opacity-50" : ""}`
          )}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Button;
