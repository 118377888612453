import SectionHeading from "@components/ui-components/SectionHeading";
import bigshyftLogo from "@assets/images/svg/bigShyftLogo.svg";

import cx from "classnames";

import Image from "next/image";

const PitchCard = ({  data, onExploreClick=()=>{} }) => {
    return (
    <section
      className="flex min-w-[calc(100%-0.8rem)] flex-col gap-5 items-center justify-center flex-grow bg-[#FFE5CC] p-5 rounded-xl"
    >
      <Image src={bigshyftLogo} width={106} height={17.5} alt="bigshyft logo" />
      <div
        className={cx("flex flex-col items-center justify-center max-h-94 flex-grow")}
      >
        <Image src={data.imgSrc} width={data.width} height={data.height} alt={data.heading}/> 
      </div>
      <SectionHeading className="max-w-[16rem] text-center dark:text-N900">
      {data?.heading}
      </SectionHeading>
      <button type="button" onClick={onExploreClick} className="bg-B900 text-N100 rounded-md text-readingTextExtraLarge leading-readingTextMedium font-semiBold text-center block min-w-[min(100%,16rem)] px-4 py-2.5">Explore</button>
    </section>
  );
};

export default PitchCard;
