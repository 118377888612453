import CheckIcon from "@assets/icons/greenCheck.svg";
import Button from "@components/ui-components/Button";
import cx from "classnames";

type Props = {
  hasOlderContent?: boolean;
  fetchFeedData?: () => void;
};

const EndOfContent = ({ hasOlderContent, fetchFeedData }: Props) => {
  return (
    <div className={cx("flex", "flex-col", "items-center", "justify-center", "text-center", "p-5")}>
      <img src={CheckIcon} alt="checked" />
      <p className="mt-2 text-readingTextLarge font-medium leading-readingTextLarge text-N900 dark:text-N100">
        You’re all caught up!
      </p>
      {hasOlderContent ? (
        <p className="text-readingTextMedium font-regular leading-readingTextMedium text-N600 dark:text-DN600">
          You’ve seen all recent content from the past
        </p>
      ) : null}
      {hasOlderContent && (
        <Button
          variant="link"
          className="mt-5 ml-auto mr-auto block font-bold"
          onClick={() => fetchFeedData()}
        >
          View Older Content
        </Button>
      )}
    </div>
  );
};

export default EndOfContent;
