import http from "@apiServices/http";
import apiUrls from "@apiServices/apiUrls";
import * as clickhouse from "@components/pages/feedPage/ch-events";
// import { trackArticleVisible } from "@components/pages/searchPage/ch-events";
import { getPageName } from "./getPageName";
class NewArticleTracking {
  constructor(configOptions = {}, callbackTimer1, callbackTimer2) {
    let options = {
      root: configOptions.root || null,
      rootMargin: configOptions.rootMargin || "0px",
      threshold: configOptions.threshold || 0.7
    };

    if (configOptions.page) {
      this.page = configOptions.page;
    } else {
      this.page = getPageName();
    }
    this.callbackTimer1 = callbackTimer1;
    this.callbackTimer2 = callbackTimer2;
    this.excludeApiCallFor = configOptions.excludeApiCallFor || [
      "discoverMoreWidget",
      "signMeUpWidget",
      "downloadAppWidget",
      "trendinArticlesWidget",
      "storyWidget1",
      "storyWidget2",
      "storyWidget3",
      "storyWidget4",
      "storyWidget5",
      "wordCoach"
    ];
    // track aticle view event only in case of feed page
    this.trackArticleViewEvent = configOptions.trackArticleViewEvent || false;
    this.slug = configOptions.slug || undefined;
    this.markAsSeenTracker = configOptions.markAsSeenTracker || true;
    this.newsId = configOptions.newsId;
    this.observer = new IntersectionObserver(this.handleIntersect, options);
    this.pausedElements = new Set();
    this.visibleCards = new Set();
    this.timerMap = new Map();
  }

  observe = (targetElem) => {
    this.observer.observe(targetElem);
  };

  destroy = () => {
    this.observer.disconnect();
    this.pausedElements = new Set();
    this.visibleCards = new Set();
    this.timerMap = new Map();
  };
  trackVisibleCard = (id, size) => {
    // let page = getPageName();
    if (id === "feature") {
      clickhouse.trackVisibleCard({
        eventName: `${this.slug ? "news_details" : this.page}_page_stories_visible`,
        eventCategory: "stories_visible"
      });
    } else if (id === "trendinArticlesWidget") {
      clickhouse.trackVisibleCard({
        eventName: `${
          this.slug ? "news_details" : this.page
        }_page_other_trending_reads_widget_visible`,
        screenName: this.slug,
        eventCategory: "other_trending_reads_widget_visible"
      });
    } else if (id === "discoverMoreWidget") {
      clickhouse.trackVisibleCard({
        eventName: `${
          this.slug ? "news_details" : this.page
        }_page_discover_more_tags_widget_visible`,
        screenName: this.slug ? "news_details" : undefined,
        eventCategory: "discover_more_tags_widget_visible",
        articleId: this.newsId
      });
    } else if (id === "signMeUpWidget") {
      clickhouse.trackVisibleCard({
        eventName: `${this.slug ? "news_details" : this.page}_page_subscribe_widget_visible`,
        eventCategory: "subscribe_widget_visible"
      });
    } else if (id === "downloadAppWidget") {
      clickhouse.trackVisibleCard({
        eventName: `${this.slug ? "news_details" : this.page}_page_download_app_widget_visible`,
        eventCategory: "download_app_widget_visible"
      });
    } else if (id === "wordCoach") {
      clickhouse.trackVisibleCard({
        eventName: `${this.slug ? "news_details" : this.page}_page_puzzles_widget_visible`,
        screenName: this.slug ? "news_details" : undefined,
        eventCategory: "puzzles_widget_visible",
        articleId: this.newsId
      });
    } else {
      const articleId = id;
      this.visibleCards.add(articleId);
      // need to review for my-feeds page - for now using common function to track article visible card
      // if (this.page === "feeds" || this.page === "naukri") {
      //   clickhouse.trackVisibleCard({
      //     eventName: `${this.page}_page_article_visible`,
      //     eventCategory: "article_visible",
      //     data: { article_id: articleId }
      //   });
      // } else {
      clickhouse.trackArticleVisible({
        page: this.slug ? "news_details" : this.page,
        data: { article_id: articleId, slug: this.slug, size }
      });
      // }
    }
  };
  // If target is more than 70% on screen start the timer to mark as seen
  startTimer = (target) => {
    if (!this.timerMap.has(target.id)) {
      const size = target.dataset.size ? target.dataset.size : "";
      const contentTag = target.dataset.contenttag ? target.dataset.contenttag : "";
      // trigger GA events for visible cards
      if (this.excludeApiCallFor.includes(target.id)) {
        this.trackVisibleCard(target.id, size);
        this.observer.unobserve(target);
      } else {
        if (!this.visibleCards.has(target.id)) {
          this.trackVisibleCard(target.id, size);
        }

        const timer1 = setTimeout(() => {
          if (this.callbackTimer1 && typeof this.callbackTimer1 === "function") {
            this.callbackTimer1(target.id);
          }
        }, 1000);

        let timer2 = setTimeout(() => {
          this.pausedElements.add(target.id);

          if (this.callbackTimer2 && typeof this.callbackTimer2 === "function") {
            this.callbackTimer2(target.id);
          }

          if (!window["newswrapVideoPlaying"] && JSON.parse(target.dataset.isopen)) {
            // not to mark as seen if newswrap video player is shown or if the article card was collapsed
            this.markAsSeen(target.id, size, contentTag);
            this.observer.unobserve(target);
          }
          // clearTimeout(this.timerMap.get(target.id).timer2);
          this.timerMap.delete(target.id);
        }, 3000);

        const timerObj = { timer1, timer2 };
        this.timerMap.set(target.id, timerObj);
      }
    }
  };

  clearTimer = (target) => {
    if (this.timerMap.has(target.id)) {
      clearTimeout(this.timerMap.get(target.id).timer1);
      clearTimeout(this.timerMap.get(target.id).timer2);
      this.timerMap.delete(target.id);
    }
  };

  handleIntersect = (entries) => {
    entries.forEach((entry) => {
      let target = entry.target;
      if (entry.intersectionRatio >= 0.7) {
        this.startTimer(target, this.slug);
      } else {
        this.clearTimer(target);
      }
    });
  };

  markAsSeen = async (articleId, size, contentTag) => {
    // handle api request
    const url = apiUrls.markAsSeen({ articleId });
    const readUrl = apiUrls.readUrl({ articleId });
    if (
      this.page.includes("trending") ||
      this.page.includes("naukri") ||
      this.page.includes("my-feeds")
    ) {
      await http.post(url, {});
    } else {
      await http.post(readUrl, {});
    }

    clickhouse.trackArticleSeen({
      pageType: this.slug ? "news_details" : this.page,
      data: { article_id: articleId, size, contentTag }
    });
  };
}

export default NewArticleTracking;
