import React, { useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import DiscoverMoreChip from "@components/pages/feedPage/DiscoverMoreChip";
import Button from "@components/ui-components/Button";
import { disableScroll } from "@utils/disableScroll";
import firestoreServices from "@firestoreServices/firestoreServices";
import TagType from "@customTypes/tag.type";
import * as clickhouse from "./ch-events";

const DiscoverMoreDrawer = dynamic(() => import("../DIscoverMoreDrawer"));

type DiscoverMoreTagsProps = {
  discoverMoreTags: Array<TagType>;
  updatePreferences: Function;
  discoverMoreChips: any;
  setDiscoverMoreChips: Function;
  stopFeedPolling?: () => void;
  resumeFeedPolling?: () => void;
  observer?: any;
  mounted?: boolean;
};

const DiscoverMoreWidget = ({
  discoverMoreTags,
  updatePreferences,
  discoverMoreChips,
  setDiscoverMoreChips,
  stopFeedPolling,
  resumeFeedPolling,
  observer = null,
  mounted = false
}: DiscoverMoreTagsProps) => {
  const [selectedChip, setSelectedChip] = useState(discoverMoreChips || []);
  const [isViewMoreCTAClicked, setIsViewMoreCTAClicked] = useState(false);
  const [openDiscoverMoreDrawer, setOpenDiscoverMoreDrawer] = useState(false);
  const discoverMoreWidgetRef = useRef(null);
  useEffect(() => {
    if (observer && discoverMoreWidgetRef && discoverMoreWidgetRef.current) {
      observer.observe(discoverMoreWidgetRef.current);
      return () => {
        // observer.destroy();
      };
    }
  }, [observer]);
  const handleOnChipClick =
    (item: { id }, type = "") =>
    async (e) => {
      e.stopPropagation();
      const firestore = new firestoreServices();
      const userFirestoreData = await firestore.getUserData();

      if (selectedChip.includes(item) || userFirestoreData?.preferences?.includes(item.id)) {
        updatePreferences(null, item, true);
        const newChipArr = selectedChip.filter((data) => data.id !== item.id);
        setSelectedChip(newChipArr);
        setDiscoverMoreChips(newChipArr);
        firestore.deleteUserPreferences(item.id);

        clickhouse.trackTagChickletClick({
          element_name: "tags_chicklet",
          data: { tag_id: item.id, state: "unselect" },
          type
        });
      } else {
        updatePreferences(null, item);
        setSelectedChip([...selectedChip, item]);
        setDiscoverMoreChips([...selectedChip, item]);
        firestore.addUserPreferences([item.id]);
        clickhouse.trackTagChickletClick({
          element_name: "tags_chicklet",
          data: { tag_id: item.id, state: "select" },
          type
        });
      }
    };

  const handleDiscoverMoreDrawerState = (e, type = "") => {
    e.stopPropagation();
    if (stopFeedPolling) {
      if (!openDiscoverMoreDrawer) {
        stopFeedPolling();
      } else {
        resumeFeedPolling();
      }
    }
    setIsViewMoreCTAClicked(true);
    setOpenDiscoverMoreDrawer(!openDiscoverMoreDrawer);
    disableScroll(!openDiscoverMoreDrawer);
    if (type === "drawer") {
      clickhouse.trackCTAs({
        type: type,
        element_name: "back_button",
        element_type: "button"
      });
    } else {
      clickhouse.trackCTAs({
        element_name: "view_all_tags_link"
      });
      clickhouse.trackDiscoverMorePage();
    }
  };

  return (
    <div
      className="flex h-full grow flex-col pt-5 pb-[1.375rem]"
      id="discoverMoreWidget"
      ref={discoverMoreWidgetRef}
    >
      <h3 className="mb-1 text-title font-bold leading-title text-N900 dark:text-DN900">
        Discover more with #tags
      </h3>
      <p className="mb-2 text-readingTextMedium font-regular leading-readingTextMedium text-DN500 dark:text-DN500">
        Also see top content about these on your feed
      </p>
      <ul className="flex grow flex-wrap">
        {discoverMoreTags.length &&
          discoverMoreTags.slice(0, 10).map((item, index) => (
            <li className="pt-2 pr-2" onClick={handleOnChipClick(item)} key={index}>
              <DiscoverMoreChip
                mounted={mounted}
                chipLabel={`#${item.value}`}
                isChipSelected={selectedChip.includes(item)}
              />
            </li>
          ))}
      </ul>
      {discoverMoreTags.length && discoverMoreTags.length - 10 > 0 && (
        <div className="mt-5 flex justify-end">
          <Button variant="link" onClick={handleDiscoverMoreDrawerState}>
            View {discoverMoreTags.length - 10} More
          </Button>
        </div>
      )}
      {mounted && isViewMoreCTAClicked && (
        <DiscoverMoreDrawer
          open={openDiscoverMoreDrawer}
          onClose={(e) => handleDiscoverMoreDrawerState(e, "drawer")}
          title="Discover more with #tags"
          tags={discoverMoreTags}
          handleOnChipClick={(item) => handleOnChipClick(item, "drawer")}
        />
      )}
    </div>
  );
};

export default DiscoverMoreWidget;
