import errorIcon from "@assets/icons/validation_error.svg";
import Button from "@components/ui-components/Button";

type Props = {
  hasOlderContent?: boolean;
  fetchFeedData?: () => void;
};

const Error = ({ hasOlderContent, fetchFeedData }: Props) => {
  return (
    <div className="flex flex-col items-center justify-center p-5 text-center">
      <img src={errorIcon} alt="checked" />
      <p className="mt-2 text-readingTextLarge font-medium leading-readingTextLarge text-N900 dark:text-N100">
        Sorry, something went wrong!
      </p>
      <p className="text-readingTextMedium font-regular leading-readingTextMedium text-N600 dark:text-DN600">
        There was an unexpected error. Please try again
      </p>
      {hasOlderContent && (
        <Button
          variant="link"
          className="mt-5 ml-auto mr-auto block font-bold"
          onClick={() => fetchFeedData()}
        >
          Retry again
        </Button>
      )}
    </div>
  );
};

export default Error;
