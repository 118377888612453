/* eslint-disable no-unused-vars */
import { useRef, useEffect, useState } from "react";
import Link from "next/link";
import cx from "classnames";
// import ArticleFooter from "@components/templates/ArticleFooter";
import s from "./Article.module.scss";
// import Button from "@components/ui-components/Button";
// import LogoDark from "@assets/icons/logoDark.svg";
import LogoDarkNew from "@assets/icons/logoDarkNew.svg";
// import LogoLight from "@assets/icons/logoLight.svg";
import LogoLightNew from "@assets/icons/logoLightNew.svg";
import Close from "@assets/icons/closeDark.svg";
// import ImgShareLight from "@assets/icons/ImgShareLight.svg";
import firestoreServices from "@firestoreServices/firestoreServices";
import ArticleDataType from "@customTypes/articleData.type";
import { observeElement } from "@utils/track-scrolling";
import useFallbackImageInSSR from "@utils/useFallbackImageInSSR";
import { useTheme } from "next-themes";
import * as clickhouse from "./ch-events";
import ThemedImage from "@components/ui-components/ThemedImage";
import { articleFallbackImageBaseUrl } from "@components/pages/feedPage/constants";
import ImgRead from "@assets/icons/seen.png";
import ImgLike from "@assets/icons/like1.png";
import ImgList from "@assets/icons/list.png";
import ImgDotLight from "@assets/icons/threeDots.png";
import ImgLikeGreen from "@assets/icons/likeGreen.png";
import GreyLeftArrow from "@assets/icons/back.svg";
import ArrowDown from "@assets/icons/down.png";
import ArrowUp from "@assets/icons/downGrey.png";
import ImgWhatsapp from "@assets/icons/whatsapp.png";
import ImgWhatsappLight from "@assets/icons/whatsappLight.png";
import { Collapse } from "react-collapse";
import ImgLiked from "@assets/icons/liked.png";
import LogoDark from "@assets/icons/logoDark.svg";
import LogoLight from "@assets/icons/logoLight.svg";
import { elapsedTime } from "@utils/getDiffInDates";
import kFormatter from "@utils/countUtil";
import { disableScroll } from "@utils/disableScroll";
import urls from "@apiServices/apiUrls";
import http from "@apiServices/http";
import dynamic from "next/dynamic";
import apiUrls from "@apiServices/apiUrls";
import objectToGetParams from "@utils/objectToGetParams";
import isMobileOrTablet from "@utils/isMobileOrTablet";
// import createShareButton from './hocs/createShareButton';

const ThreeDotDrawer = dynamic(() => import("@components/templates/ThreeDotDrawer"));
const ReportDrawer = dynamic(() => import("@components/templates/ReportDrawer"));
const ArticlesBadge = dynamic(() => import("../ArticlesBadge"));

type ArticleProps = {
  isSourceHidden?: boolean;
  titleClickable?: boolean;
  closeDownloadAppDrawer?: () => void;
  articleData: ArticleDataType;
  isLoading?: boolean;
  userLikeArticles?: Array<string>;
  userBookmarkArticles?: Array<string>;
  removeNotInterestedArticle?: Function;
  hideFollowOptionFromThreeDotDrawer?: boolean;
  isTrending: boolean;
  className?: string;
  // followingPreferences: Array<any>;
  // suggestedPreferences: Array<any>;
  // preferenceUpdated: (
  //   followingPreferences: Array<any>,
  //   suggestedPreferences: Array<any>
  // ) => void;
  isSkip?: boolean;
  preferences?: Array<string>;
  setPreferences?: Function;
  toggleFollowBtn?: Function;
  savedKeyword?: any;
  isFollowing?: boolean;
  setRemoveCount?: Function;
  removeCount?: number;
  index: number;
  groupName?: string;
  hideThreeDotButton?: boolean;
  updateSavedKeywords?: Function;
  updatePreferences?: Function;
  handleRemoveBookmark?: Function;
  showFullDescription?: boolean;
  observer?: any;
  lazyLoadImage?: boolean;
  imageObserver?: any;
  page?: string;
  slug?: string;
  articleSeen?: boolean;
  seenArticleList?: Array<any>;
  articleRead?: boolean;
  readArticleList?: Array<any>;
  handleOnRead?: Function;
};

const Article = ({
  // closeDownloadAppDrawer,
  articleData,
  isLoading,
  userLikeArticles,
  // userBookmarkArticles,
  removeNotInterestedArticle,
  hideFollowOptionFromThreeDotDrawer = false,
  // isTrending,
  // followingPreferences,
  // suggestedPreferences,
  // isSkip,
  // preferences,
  // setPreferences,
  className,
  // isSourceHidden,
  savedKeyword = null,
  // isFollowing = false, // earlier set to false
  removeCount = 0,
  toggleFollowBtn = () => {},
  setRemoveCount = () => {},
  index,
  // groupName = "",
  // hideThreeDotButton,
  updateSavedKeywords = () => {},
  updatePreferences = () => {},
  // handleRemoveBookmark = () => {},
  showFullDescription = false,
  observer = null,
  lazyLoadImage = true,
  imageObserver = null,
  page = "",
  slug = "",
  seenArticleList = [],
  articleSeen = false,
  articleRead = false,
  titleClickable = true,
  handleOnRead
}: ArticleProps) => {
  // const [isFollow, setFollow] = useState(
  //   (articleData?.tagIds?.length > 0 && preferences?.includes(articleData?.tagIds[0])) ||
  //     !!articleData?.savedKeyword
  // );
  const [isUserLikeArticle, setIsUserLikeArticle] = useState(
    userLikeArticles?.includes(articleData.id) ? true : false
  );
  const [isArticleSeen, setIsArticleSeen] = useState(articleSeen);
  const [openThreeDotDrawer, setOpenThreeDotDrawer] = useState(false);
  const [isLikeCountUpdating, setIsLikeCountUpdating] = useState(false);
  const [likesCount, setLikesCount] = useState(articleData.likeCount);
  const [openNotInterestedDrawer, setOpenNotInterestedDrawer] = useState(false);
  const [openReportDrawer, setOpenReportDrawer] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  // const [isArticleSeen, setIsArticleSeen] = useState(articleSeen);
  // useEffect(() => {
  //   setFollow(
  //     (articleData?.tagIds?.length > 0 && preferences?.includes(articleData?.tagIds[0])) ||
  //       !!articleData?.savedKeyword
  //   );
  // }, [
  //   (articleData?.tagIds?.length > 0 && preferences?.includes(articleData?.tagIds[0])) ||
  //     !!articleData?.savedKeyword
  // ]);

  useEffect(() => {
    if (seenArticleList.includes(articleData.id)) {
      setIsArticleSeen(true);
    }
  }, [articleSeen, seenArticleList]);

  // const [imgDetail, setImg] = useState(null);
  // const [isImgLoading, setIsImgLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const articleCardRef = useRef(null);
  const { resolvedTheme } = useTheme();
  const sourceRef = useRef(null);

  useEffect(() => {
    setIsUserLikeArticle(userLikeArticles?.includes(articleData.id) ? true : false);
  }, [userLikeArticles]);

  // useEffect(() => {
  //   if (!isArticleSeen) setIsArticleSeen(articleSeen);
  //   const el = document.getElementById(`share-${articleData.id}`);
  //   if (articleSeen) {
  //     el.classList.add("shake");
  //   }
  //   //share shake animation
  //   const timer = setTimeout(() => {
  //     el.classList.remove("shake");
  //   }, 4400);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [articleSeen]);

  useEffect(() => setMounted(true), []);

  // useEffect(() => {
  //   if (!lazyLoadImage) {
  //     const loadImage = (url) =>
  //       new Promise((resolve, reject) => {
  //         const img = new Image();
  //         img.addEventListener("load", () => resolve(img));
  //         img.addEventListener("error", (err) => reject(err));
  //         img.src = url ? url : null;
  //       });
  //     const getImg = async () =>
  //       await loadImage(articleData?.mediaURL)
  //         .then((el: any) => el.src)
  //         .catch(() => {
  //           if (articleData?.channelIds.length > 0) {
  //             return `https://techminis.com/api/suggestions/channel/placeholder/${articleData.channelIds[0]}.png`;
  //           } else {
  //             return null;
  //           }
  //         })
  //         .finally(() => {
  //           setIsImgLoading(false);
  //         });
  //     if (articleData?.mediaURL) {
  //       setImg(articleData?.mediaURL);
  //       getImg().then((el) => {
  //         setImg(el);
  //       });
  //     } else {
  //       if (articleData?.channelIds.length > 0) {
  //         setImg(
  //           `https://techminis.com/api/suggestions/channel/placeholder/${articleData.channelIds[0]}.png`
  //         );
  //       } else {
  //         setImg(null);
  //       }
  //       setIsImgLoading(false);
  //     }
  //   }
  // }, [lazyLoadImage]);

  useEffect(() => {
    let unsubscribeObserver = () => {};
    if (articleCardRef && articleCardRef.current) {
      unsubscribeObserver = observeElement(articleCardRef.current);
    }

    return unsubscribeObserver;
  }, []);

  // auto scroll and open if the articleId is same
  // useEffect(() => {
  //   const urlSearchParams = new URLSearchParams(window?.location?.search);
  //   const params = Object.fromEntries(urlSearchParams.entries());
  //   const { articleIds } = params;
  //   let timer = null;
  //   if (articleIds && params.articleIds.split(",")[0] === articleData.id) {
  //     timer = setTimeout(() => {
  //       setIsOpen(true);
  //       checkElementInView();
  //       markAsSeen(articleData.id);
  //       clearTimeout(timer);
  //     }, 2000);
  //   }
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  // const handleFollowArticles = async (e) => {
  //   e.stopPropagation();
  //   const firestore = new firestoreServices();
  //   if (!isFollow) {
  //     setFollow(true);
  //     updatePreferences(articleData, null);
  //     setPreferences([...preferences, ...articleData?.tagIds]);
  //     localStorage.removeItem("skip");
  //     clickhouse.trackArticleFollowClick({
  //       element_name: "article_card_follow_link",
  //       data: { article_id: articleData?.id, tag_id: articleData?.tagIds[0] }
  //     });
  //     await firestore.addUserPreferences(articleData?.tagIds);
  //   } else {
  //     setFollow(false);
  //     updatePreferences(articleData, null, true);
  //     clickhouse.trackArticleUnfollowClick({
  //       element_name: "article_card_following_link",
  //       data: { article_id: articleData?.id, tag_id: articleData?.tagIds[0] }
  //     });
  //     articleData?.tagIds.map(async (tagId) => {
  //       setPreferences(preferences.filter((id) => id != tagId));
  //       await firestore.deleteUserPreferences(tagId);
  //     });
  //   }
  // };
  const handleLikeArticle = async () => {
    setIsUserLikeArticle(true);
    setIsLikeCountUpdating(true);
    setLikesCount(likesCount + 1);
    const firestore = new firestoreServices();

    clickhouse.trackArticleCardFooterClick({
      element_name: "article_card_like_icon",
      data: { article_id: articleData?.id, state: "select", size: "small_article_card" }
    });

    await firestore.addUserArticlesLike(articleData?.id);
    http
      .post(urls.likeArticle({ articleId: articleData?.id }), {})
      .then(() => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLikeCountUpdating(false);
      });
  };

  const handleUnLikeArticle = async () => {
    setIsUserLikeArticle(false);
    setIsLikeCountUpdating(true);
    setLikesCount(likesCount - 1);
    const firestore = new firestoreServices();

    clickhouse.trackArticleCardFooterClick({
      element_name: "article_card_unlike_icon",
      data: { article_id: articleData?.id, state: "unselect", size: "small_article_card" }
    });

    await firestore.deleteUserArticlesLike(articleData?.id);
    http
      .post(urls.unlikeArticle({ articleId: articleData?.id }), {})
      .then(() => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLikeCountUpdating(false);
      });
  };

  useEffect(() => {
    if (observer && articleCardRef && articleCardRef.current) {
      observer.observe(articleCardRef.current);
      return () => {
        // observer.destroy();
      };
    }
  }, [observer]);

  useEffect(() => {
    if (imageObserver && articleCardRef && articleCardRef.current && lazyLoadImage) {
      imageObserver.observe(articleCardRef.current);
      return () => {
        // observer.destroy();
      };
    }
  }, [imageObserver]);

  // const RenderSupportingMessage = ({ _isSkip = false, articleData = null }) => {
  //   const input =
  //     articleData && articleData?.tags?.length
  //       ? articleData?.tags[0]
  //       : articleData && articleData?.savedKeyword
  //       ? articleData?.savedKeyword
  //       : "";

  //   if (isTrending) {
  //     return <span> {`Trending in #${input}`}</span>;
  //   }
  //   // if (_isSkip) {
  //   //   return <span>{`Based on #${input}`}</span>;
  //   // }

  //   return <span>{`Because you follow #${input}`}</span>;
  // };
  const fallbackImageProps = useFallbackImageInSSR(
    resolvedTheme === "dark" ? LogoDarkNew : LogoLightNew
  );

  const handleNotInterestedSubmit = async (id, reason = "") => {
    const firestore = new firestoreServices();
    await firestore.addUserNotInteresetedArticles(articleData.id);
    removeNotInterestedArticle(articleData.id, "NOT_INTERESTED", reason);
    setRemoveCount(removeCount + 1);

    clickhouse.trackArticleNotInterestedSubmitClick({
      screenName: page === "news_details" ? page : "",
      pageType: page === "news_details" ? page : "",
      element_name: "notinterested_bottomsheet_submit_button",
      data: {
        article_id: articleData?.id,
        notinterested_reason: reason,
        size: "small_article_card"
      }
    });

    // await handleFirestoreData();
  };

  const handleReportSubmit = async (id, reason = "") => {
    const firestore = new firestoreServices();
    await firestore.addUserReportArticles(articleData.id);
    removeNotInterestedArticle(articleData.id, "REPORT", reason);
    setRemoveCount(removeCount + 1);
    // if (type === "stories") {
    //   clickhouse.trackArticleNotInterestedSubmitClick({
    //     // screenName: "stories",
    //     element_name: "report_bottomsheet_submit_button",
    //     data: { article_id: articleData?.id, notinterested_reason: reason }
    //   });
    // } else {
    clickhouse.trackArticleReportSubmitClick({
      screenName: page === "news_details" ? page : "",
      pageType: page === "news_details" ? page : "",
      element_name: "report_bottomsheet_submit_button",
      data: { article_id: articleData?.id, report_reason: reason, size: "small_article_card" }
    });
    // }
    // await handleFirestoreData();
  };

  const titleShareUrl = `/${articleData.slug}/${articleData.id}`;

  const shareUrl = articleData?.shortUrl
    ? articleData?.shortUrl
    : `/naukri?articleIds=${articleData.id}`;

  const markAsSeen = async (articleId) => {
    // handle api request
    const url = apiUrls.markAsSeen({ articleId });

    await http.post(url, {});

    clickhouse.trackArticleSeen({
      pageType: slug ? "news_details" : page,
      data: { article_id: articleId, size: "small_article_card" }
    });
  };

  const summaryEl: any = useRef();

  const checkElementInView = () => {
    if (!articleCardRef.current) return;

    const element = articleCardRef.current;
    const container =
      document.getElementById("unsupported-browser-container") ||
      document.getElementById("container");

    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const containerTop = containerRect.top;
    const containerBottom = containerRect.bottom;
    const elementTop = elementRect.top;
    const elementBottom = elementRect.bottom;

    const offset = 90;

    if (elementTop >= containerTop && elementBottom <= containerBottom - offset) {
      //already in viewport
    } else {
      container.style.scrollPaddingTop = "73px";
      element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  };

  return (
    <div
      className={`${className ? className : "flex h-full flex-col justify-between"}`}
      ref={articleCardRef}
      id={articleData.id}
      data-id={articleData.id}
      data-isopen={JSON.stringify(isOpen)}
      data-size="small_article_card"
      data-contenttag={articleData?.contentTag || ""}
      data-index={index}
    >
      <div
        className="flex cursor-default flex-col overflow-hidden px-5"
        style={{ height: "calc(100% - 85px)" }}
      >
        <div className="flex items-center"></div>

        <div className="flex flex-col justify-between" style={{ height: "calc(100% - 28px)" }}>
          <div
            className={`flex items-stretch justify-between`}
            // onClick={() => {
            //   setIsOpen(!isOpen);

            //   if (!isOpen) {
            //     checkElementInView();
            //     handleOnRead(articleData.id);
            //     // markAsSeen(articleData.id);
            //     clickhouse.trackCTAs({
            //       element_name: "summary_open_button",
            //       data: { size: "small_article_card" }
            //     });
            //   }
            // }}
          >
            <div className="relative flex flex-col ">
              <div className="flex items-center justify-between">
                <div className="flex">
                  <div className="mr-1 flex items-center" ref={sourceRef}>
                    {lazyLoadImage ? (
                      <ThemedImage
                        className="lazy flex h-5 w-5 items-center justify-center rounded-md bg-N200 object-cover dark:bg-DN200"
                        mounted={mounted}
                        resolvedTheme={resolvedTheme}
                        darkSrc={LogoDark}
                        lightSrc={LogoLight}
                        dataSrc={articleData?.sourceLogo}
                        width={20}
                        height={20}
                        alt="source image"
                        dataImageObserver={true}
                        // {...fallbackImageProps}
                      />
                    ) : (
                      <img
                        src={articleData?.sourceLogo}
                        width={20}
                        height={20}
                        className="flex h-5 w-5 items-center justify-center rounded-full bg-N200 object-cover dark:bg-DN200"
                        alt="source image"
                        {...fallbackImageProps}
                      />
                    )}
                  </div>
                  <div
                    className={
                      "font-normal mt-[2.2px] text-readingTextSmall leading-readingTextSmall text-N500 dark:text-DN500"
                    }
                  >
                    {isLoading ? (
                      <div className="h-5 w-[150px] animate-pulse bg-N200 dark:bg-DN200" />
                    ) : articleData?.publishedDate ? (
                      <div className="flex items-center">
                        <p className="mr-2 font-medium">{articleData?.sourceName}</p>
                        <div className="">{elapsedTime(articleData.publishedDate)}</div>
                      </div>
                    ) : (
                      `${articleData?.sourceName}`
                    )}
                  </div>
                </div>

                {articleData?.contentTag && <ArticlesBadge type={articleData?.contentTag} />}
              </div>

              <div className="flex justify-between">
                <h3
                  className={`${
                    isOpen ? "" : "line-clamp-4"
                  } mt-2 mr-2 text-readingTextLarge font-semiBold leading-readingTextLarge text-N900 dark:text-N100`}
                  id={`article-${index}`}
                >
                  {/* {!titleClickable ? (
                  <Link href={titleShareUrl} passHref prefetch={false}>
                    <a title={articleData.title}>
                      <span className="mr-2">
                        {isLoading ? (
                          <div className="h-full w-full animate-pulse bg-N200 dark:bg-DN200" />
                        ) : (
                          articleData.title
                        )}
                      </span>
                    </a>
                  </Link>
                ) : ( */}
                  <p title={articleData.title}>
                    <span className="mr-2">
                      {isLoading ? (
                        <div className="h-full w-full animate-pulse bg-N200 dark:bg-DN200" />
                      ) : (
                        articleData.title
                      )}
                    </span>
                  </p>
                  {/* )} */}
                </h3>
                {/* {!isOpen && (
                  <div
                    className="flex w-fit cursor-pointer items-center"
                    onClick={() => {
                      setIsOpen(true);

                      if (!isOpen) {
                        checkElementInView();
                        handleOnRead(articleData.id);
                        // markAsSeen(articleData.id);
                        clickhouse.trackCTAs({
                          element_name: "summary_open_button",
                          data: { size: "small_article_card", article_id: articleData.id }
                        });
                      }
                    }}
                  >
                    <p className="mt-1.5 text-readingTextMedium font-bold leading-readingTextMedium text-[#F94929F0]">
                      Summary
                    </p>
                    <img src={ArrowDown} width={14} height={14} className="ml-1 mt-2" alt="icon" />
                  </div>
                )} */}

                <div className="relative mt-1.5 flex w-1/3 min-w-[94px] flex-col items-end transition-all delay-500">
                  {lazyLoadImage ? (
                    <ThemedImage
                      className="lazy h-full h-[90px] w-[90px] rounded-xl border border-N300 bg-N200 object-cover dark:border-DN300 dark:bg-DN200"
                      mounted={mounted}
                      resolvedTheme={resolvedTheme}
                      darkSrc={LogoDarkNew}
                      lightSrc={LogoLightNew}
                      dataSrc={
                        articleData?.mediaURL
                          ? articleData?.mediaURL
                          : `${articleFallbackImageBaseUrl}${articleData.channelIds[0]}.png`
                      }
                      width="90px"
                      height="90px"
                      dataImageObserver={true}
                      alt="img"
                      // {...fallbackImageProps}
                    />
                  ) : (
                    <img
                      src={
                        articleData?.mediaURL
                          ? articleData?.mediaURL
                          : `${articleFallbackImageBaseUrl}${articleData.channelIds[0]}.png`
                      }
                      width="90px"
                      height="90px"
                      className="h-full h-[90px] w-[90px] rounded-xl border border-N300 bg-N200 object-cover dark:border-DN300 dark:bg-N200"
                      alt="img"
                      {...fallbackImageProps}
                    />
                  )}
                  <div
                    className="absolute right-2 top-2 rounded-tl rounded-br bg-[#00000080] py-[3px] px-[2px]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenThreeDotDrawer(true);
                      localStorage.setItem("drawerOpen", "true");
                      disableScroll(true);
                      clickhouse.trackArticleCardFooterClick({
                        pageType: page === "news_details" ? page : undefined,
                        screenName: page === "news_details" ? page : "",
                        element_name: "article_card_threedots_icon",
                        data: {
                          article_id: articleData?.id,
                          slug: slug,
                          size: "small_article_card"
                        }
                      });
                    }}
                  >
                    <img src={ImgDotLight} alt="dot" height={16} width={16} />
                  </div>
                </div>
              </div>
              <div
                ref={summaryEl}
                className={`${s.collapse} ${isOpen ? s.show : ""}`}
                style={
                  isOpen ? { height: summaryEl.current?.scrollHeight || "100%" } : { height: "0px" }
                }
              >
                {!isLoading && (
                  <>
                    <div
                      className={cx(
                        s.summaryWrap,
                        showFullDescription && "mb-3",
                        "mt-3 pl-5 text-readingTextMedium font-regular leading-readingTextMedium text-N700 dark:text-N300"
                      )}
                      dangerouslySetInnerHTML={{ __html: articleData.summary }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <div>
              {!isLoading && (
                <>
                  <div
                    className={`${
                      articleData.sourceName !== "Techminis" ? "justify-between" : "justify-end"
                    } flex w-full items-center`}
                  >
                    {/* <div
                      className="flex w-fit cursor-pointer items-center"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        markAsSeen(articleData.id);
                        clickhouse.trackCTAs({
                          element_name: "summary_close_button",
                          data: { size: "image_title_card", article_id: articleData.id }
                        });
                      }}
                    >
                      <p className=" text-right text-readingTextMedium font-bold leading-readingTextMedium text-N500">
                        Read Less
                      </p>
                      <img
                        src={ArrowUp}
                        width={14}
                        height={14}
                        className="ml-1 mt-0.5 rotate-180"
                        alt="icon"
                      />
                    </div> */}
                    {articleData.sourceName !== "Techminis" ? (
                      <p
                        className="text-readingTextMedium font-semiBold leading-readingTextMedium text-P500"
                        onClick={(e) => {
                          // setOpenDownloadAppDrawer(true);
                          e.stopPropagation();
                          if (articleData.fullURL) {
                            window.open(articleData.fullURL, "_blank");
                          }
                          clickhouse.trackCTAs({
                            pageType: page === "news_details" ? page : undefined,
                            screenName: page === "news_details" ? page : "",
                            element_name: "article_card_read_full_article_link",
                            data: {
                              article_id: articleData.id,
                              slug: slug,
                              size: "image_title_card"
                            }
                          });
                        }}
                      >
                        Read Full Article
                      </p>
                    ) : null}
                    {isArticleSeen ? (
                      <div
                        className={`${s.up_animation} rounded border-[1.5px] border-[#40C057] px-1 py-0.5`}
                        style={{ width: "fit-content" }}
                      >
                        <p className="font-normal text-readingTextSmall font-semiBold leading-readingTextSmall text-[#40C057]">
                          {articleData.shareCount > 0
                            ? `Shared ${articleData.shareCount} times`
                            : "Be the first one to share"}
                        </p>
                      </div>
                    ) : (
                      <div className="h-[23px]"></div>
                    )}
                  </div>
                </>
              )}
            </div>

            <div
              className={`relative mt-4 mb-2 flex items-center justify-between ${
                isOpen ? "border-t-[1px] border-P100 dark:border-DG700" : ""
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="mt-1.5 flex w-full items-center justify-between">
                  <p className="text-readingTextMedium font-medium leading-readingTextMedium text-N400">
                    {kFormatter(articleData.readCount)} reads
                  </p>
                </div>
              </div>

              <div className="mt-1.5 flex items-center justify-between">
                {isUserLikeArticle ? (
                  <div
                    className="flex cursor-pointer items-center justify-center border-P100 dark:border-DG700"
                    onClick={() => {
                      if (!isLikeCountUpdating) handleUnLikeArticle();
                    }}
                  >
                    <img
                      src={ImgLiked}
                      width={20}
                      height={20}
                      className="mr-1 h-5 w-5"
                      alt="like"
                      {...fallbackImageProps}
                    />
                    <p
                      className={`${
                        isUserLikeArticle
                          ? "font-medium text-[#ff0000]"
                          : "font-medium text-N400 dark:text-[#64728A]"
                      } mt-[2px] text-readingTextMedium leading-readingTextMedium`}
                    >
                      {likesCount > 1 ? `${likesCount}` : `${likesCount}`}
                    </p>
                  </div>
                ) : (
                  <div
                    className="ml-2 flex cursor-pointer items-center justify-center border-P100 dark:border-DG700"
                    onClick={() => {
                      if (!isLikeCountUpdating) handleLikeArticle();
                    }}
                  >
                    <img
                      src={ImgLike}
                      width={20}
                      height={20}
                      className="mr-1 h-5 w-5"
                      alt="like"
                      {...fallbackImageProps}
                    />

                    <p
                      className={`${
                        isUserLikeArticle ? "text-[#40C057]" : "text-N400"
                      } mt-[2px]  text-readingTextMedium font-medium leading-readingTextMedium`}
                    >
                      {likesCount > 0 ? (likesCount > 1 ? `${likesCount}` : `${likesCount}`) : "0"}
                    </p>
                  </div>
                )}
                <div
                  className="ml-2 flex items-center justify-center"
                  onClick={async () => {
                    clickhouse.trackArticleCardFooterClick({
                      pageType: page === "news_details" ? page : undefined,
                      screenName: page === "news_details" ? page : "",
                      element_name: "article_card_share_icon",
                      data: {
                        article_id: articleData?.id,
                        slug: page === "news_details" ? slug : undefined,
                        size: "small_article_card"
                      }
                    });

                    const shareCountUrl = apiUrls.shareCount({ articleId: articleData.id });
                    await http.post(shareCountUrl, {});
                  }}
                >
                  <a
                    className="flex h-full w-full items-center justify-center"
                    href={
                      "https://" +
                      (mounted && isMobileOrTablet() ? "api" : "web") +
                      ".whatsapp.com/send" +
                      objectToGetParams({
                        text: `${articleData.title} \n\nvia Techminis - Your daily dose of tech\n\n${shareUrl}`
                      })
                    }
                    data-action="share/whatsapp/share"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      className="flex"
                      id={`share-${articleData.id}`}
                      style={{ transition: "all 0.4s linear" }}
                    >
                      {/* {isArticleSeen ? ( */}
                      {articleData.shareCount > 0 ? (
                        <img
                          src={ImgWhatsapp}
                          width={20}
                          height={20}
                          className="mr-1 h-5 w-5"
                          alt="share"
                          {...fallbackImageProps}
                        />
                      ) : (
                        <img
                          src={ImgWhatsappLight}
                          width={20}
                          height={20}
                          className="mr-1 h-5 w-5"
                          alt="share"
                          {...fallbackImageProps}
                        />
                      )}

                      <p
                        className={`text-readingTextMedium font-medium leading-readingTextMedium ${
                          articleData.shareCount > 0 ? "text-[#40C057]" : "text-N400"
                        }`}
                      >
                        {articleData.shareCount > 0
                          ? articleData.shareCount > 1
                            ? `${articleData.shareCount}`
                            : `${articleData.shareCount}`
                          : "0"}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {openThreeDotDrawer && (
            <ThreeDotDrawer
              open={openThreeDotDrawer}
              onClose={() => {
                setOpenThreeDotDrawer(false);
                localStorage.removeItem("drawerOpen");
                disableScroll(false);
              }}
              articleData={articleData}
              removeNotInterestedArticle={removeNotInterestedArticle}
              hideFollowOption={hideFollowOptionFromThreeDotDrawer}
              savedKeyword={savedKeyword}
              toggleFollowBtn={toggleFollowBtn}
              isFollowing={false}
              setRemoveCount={setRemoveCount}
              removeCount={removeCount}
              updateSavedKeywords={updateSavedKeywords}
              updatePreferences={updatePreferences}
              setArticleFollow={() => {}}
              isArticleFollow={false}
              timeStamp={null}
              type={""}
              videoPaused={() => {}}
              isBlur={false}
              page={page}
              openNotInterestedDrawer={openNotInterestedDrawer}
              setOpenNotInterestedDrawer={setOpenNotInterestedDrawer}
              openReportDrawer={openReportDrawer}
              setOpenReportDrawer={setOpenReportDrawer}
              size="small_article_card"
            />
          )}
          {openNotInterestedDrawer && (
            <ReportDrawer
              open={openNotInterestedDrawer}
              onClose={() => {
                setOpenNotInterestedDrawer(false);
                disableScroll(false);
                // videoPaused(false);
              }}
              title="Help us understand why"
              titleDesc="Your feedback will help us improve your experience"
              chipList={[
                "Dislike the content",
                "Seen this before",
                "Not interested in topic",
                "Content is old",
                "Something else"
              ]}
              submitTitle="Feedback Submitted!"
              submitDesc="Thanks for sharing! Your feedback will help us improve the app experience"
              onSubmit={handleNotInterestedSubmit}
              articleData={articleData}
              isReport={false}
              timeStamp={2000}
              isBlur={false}
            />
          )}
          {openReportDrawer && (
            <ReportDrawer
              open={openReportDrawer}
              onClose={() => {
                setOpenReportDrawer(false);
                disableScroll(false);
                // videoPaused(false);
              }}
              title="What exactly went wrong here?"
              titleDesc="Your report remains anonymous & we’ll take necessary actions if it goes against our guidelines"
              chipList={[
                "Content is offensive or abusive",
                "Spam",
                "False information",
                "Copyright Violation",
                "Something else"
              ]}
              submitTitle="Report Submitted!"
              submitDesc="Thanks for letting us know! We’ll look into the problem & work on it to improve your experience"
              onSubmit={handleReportSubmit}
              articleData={articleData}
              isReport={true}
              timeStamp={2000}
              isBlur={false}
            />
          )}
        </div>
      </div>
      {/* <div
        className={`mb-2 flex px-5 ${
          articleData.sourceName !== "Techminis" ? "justify-between" : "justify-end"
        }`}
      >
        {/* {articleData.sourceName !== "Techminis" ? ( */}
      {/* <p
          className="text-readingTextMedium font-semiBold leading-readingTextMedium text-P500"
          onClick={() => {
            // setOpenDownloadAppDrawer(true);
            if (articleData.fullURL) {
              window.open(articleData.fullURL, "_blank");
            }
            clickhouse.trackCTAs({
              pageType: page === "news_details" ? page : undefined,
              screenName: page === "news_details" ? page : "",
              element_name: "article_card_read_full_article_link",
              data: { article_id: articleData.id, slug: slug }
            });
          }}
        >
          Read Full Article
        </p> */}
      {/* ) : null} */}
      {/* {isArticleSeen && articleData.shareCount < 1 ? (
          <div
            className={`${s.right_animation} rounded border-[1.5px] border-[#40C057] px-1 py-0.5`}
            style={{ width: "fit-content" }}
          >
            <p className="font-normal text-readingTextSmall font-semiBold leading-readingTextSmall text-[#40C057]">
              Be the first one to share
            </p>
          </div>
        ) : (
          <div className="h-[23px]"></div>
        )} */}
      {/* </div> */}
    </div>
  );
};

export default Article;
