const DEFAULT_FONT_CONFIG = {
  regular: "text-readingTextMedium leading-readingTextMedium",
  small: "text-readingTextSmall leading-readingTextSmall",
  large: "text-readingTextLarge leading-readingTextLarge",
};

const DEFAULT_PADDING_CONFIG = {
  regular: "px-4 py-2.5",
  small: "px-2 py-1.5",
  large: "px-4 py-3",
};

export const DEFAULT_VARIANT_CONFIG = ({ size }) => ({
  button: {
    primary: `${DEFAULT_FONT_CONFIG[size]} ${DEFAULT_PADDING_CONFIG[size]} bg-P500 shadow-E1 shadow-[#5c6d981a] text-N100  active:bg-P700 dark:active:bg-DP700`,
    secondary: `${DEFAULT_FONT_CONFIG[size]} ${DEFAULT_PADDING_CONFIG[size]} text-P500 bg-N100 dark:bg-DN050 border border-solid border-P500 active:bg-P100 dark:active:bg-DP100`,
  },
  link: DEFAULT_FONT_CONFIG[size],
});
