import { useCallback, useEffect, useState } from "react";

const useHash = () => {
    const [hash, setHash] = useState(() => null);

    const hashChangeHandler = useCallback(() => {
        setHash(window.location.hash);
    }, []);

    useEffect(() => {
        window.addEventListener("hashchange", hashChangeHandler);
        return () => {
            window.removeEventListener("hashchange", hashChangeHandler);
        };
    }, []);

    const updateHash = useCallback(
        (newHash) => {
            if (newHash !== hash) window.location.hash = newHash;
        },
        [hash]
    );

    return [hash, updateHash];
};

export default useHash;
