import React from "react";
import BackImg from "@assets/icons/back.svg";
// TODO: Need to replace illustration with reduced size
import IllustrationError from "@assets/illustrations/errorLight.svg";
import IllustrationErrorDark from "@assets/illustrations/errorDark.svg";
import IllustrationOffline from "@assets/illustrations/offlineLight.svg";
import IllustrationOfflineDark from "@assets/illustrations/offlineDark.svg";
import IllustrationReading from "@assets/illustrations/savedForLaterLight.svg";
import IllustrationReadingDark from "@assets/illustrations/savedForLaterDark.svg";
import Button from "@components/ui-components/Button";
import { useTheme } from "next-themes";
import FeedNavigationBar from "@components/pages/feedPage/NavigationBar/feedNavigationBar";

type EmptyStateViewType = {
  type: "feedsError500" | "error500" | "offline" | "noReadingList" | "noFeed";
  onClick: () => void;
  handleOnClickBack?: () => void;
  noNavigationBar?: boolean;
  followingPreferences?: Array<any>;
  suggestedPreferences?: Array<any>;
  savedKeywords?: Array<any>;
  preferenceUpdated?: (
    _followingPreference: Array<any>,
    _suggestedPreference: Array<any>,
    _updatedSavedKeywords: Array<any>
  ) => void;
  className?: string;
  mounted?: boolean;
};
const EmptyStateView = ({
  type,
  onClick,
  handleOnClickBack,
  followingPreferences,
  suggestedPreferences,
  savedKeywords,
  noNavigationBar = false,
  preferenceUpdated,
  className,
  mounted = false
}: EmptyStateViewType) => {
  const { theme } = useTheme();

  const getDetails = (type) => {
    if (mounted) {
      document.documentElement.style.position = "relative";
      document.documentElement.style.height = "100%";
      document.body.style.position = "relative";
    }

    const detail = {
      illistration: null,
      title: "",
      content: "",
      buttonLabel: ""
    };
    switch (type) {
      case "error500": {
        detail.illistration = theme === "dark" ? IllustrationErrorDark : IllustrationError;
        detail.title = "Sorry, something went wrong!";
        detail.content = "There was an unexpected error. Please try again";
        detail.buttonLabel = "Try again";
        break;
      }
      case "feedsError500": {
        detail.illistration = theme === "dark" ? IllustrationErrorDark : IllustrationError;
        detail.title = "Sorry, something went wrong!";
        detail.content = "There was an unexpected error. Please try again";
        detail.buttonLabel = "Try again";
        break;
      }
      case "offline": {
        detail.illistration = theme === "dark" ? IllustrationOfflineDark : IllustrationOffline;
        detail.title = "Oops, looks like you’re offline";
        detail.content = "Check your network settings, or connect to Wi-fi";
        detail.buttonLabel = "Open network settings";
        break;
      }
      case "noReadingList": {
        detail.illistration = theme === "dark" ? IllustrationReadingDark : IllustrationReading;
        detail.title = "Save an article to create a reading list";
        detail.content =
          "Bookmarking helps you keep track of articles you like so that you can read it at your own time";
        detail.buttonLabel = "Continue Browsing ";
        break;
      }
      case "noFeed": {
        detail.illistration = theme === "dark" ? IllustrationErrorDark : IllustrationError;
        detail.title = "No content available";
        detail.content = "";
        detail.buttonLabel = "Go Back";
        break;
      }
      default: {
        detail.illistration = theme === "Dark" ? IllustrationErrorDark : IllustrationError;
        detail.title = "Sorry, something went wrong!";
        detail.content = "There was an unexpected error. Please try again";
        detail.buttonLabel = "Try again";
        break;
      }
    }
    return detail;
  };

  return (
    <div className={className}>
      {noNavigationBar && (
        <>
          {type === "feedsError500" ? (
            <FeedNavigationBar
              followingPreferences={followingPreferences}
              suggestedPreferences={suggestedPreferences}
              savedKeywords={savedKeywords}
              headerPosition="fixed"
              onLogoClick={onClick}
              handleOnBackFromCustomizeYourFeed={(
                followingPreference,
                suggestedPreference,
                updatedSavedKeywords
              ) => {
                preferenceUpdated(followingPreference, suggestedPreference, updatedSavedKeywords);
              }}
              handleOnSignup={() => {}}
              stopPolling={() => {}}
            />
          ) : (
            <img src={BackImg} alt="back" onClick={handleOnClickBack} />
          )}
        </>
      )}
      <div className="flex h-full w-full flex-col items-center justify-center">
        <img
          src={getDetails(type).illistration}
          width={200}
          height={200}
          loading="lazy"
          alt="illustration"
        />
        <p className="mt-8 text-center text-readingTextLarge font-semiBold leading-readingTextLarge text-N800 dark:text-DN800">
          {getDetails(type).title}
        </p>
        <p className="leading-text-readingTextMedium dark:DN600 font-normal mt-1 text-center text-readingTextMedium text-N600">
          {getDetails(type).content}
        </p>
        <Button
          variant="link"
          className="mt-4 block text-readingTextMedium font-bold leading-readingTextMedium text-P500"
          onClick={onClick}
          appearance="primary"
          size="regular"
        >
          {getDetails(type).buttonLabel}
        </Button>
      </div>
    </div>
  );
};
export default EmptyStateView;
