import CompaniesImage from "@assets/images/svg/recommendation_companies.svg";
import CounsellorImage from "@assets/images/png/counsellor.png";
import TalentImage from "@assets/images/png/talents.png";

export const config = [
  {
    imgSrc: CompaniesImage,
    height: 115,
    width: 208,
    heading: "Get the best jobs with no extra effort",
    subHeading: "We guarantee guidance & feedback on all your applications",
    redirectionUrl:`${process.env.NEXT_PUBLIC_JOBSEEKER_BASE_URL}/software-jobs?utm_source=techminis&utm_campaign=techminis_`
  },
  {
    height: 105,
    width: 196,
    imgSrc: TalentImage,
    heading: "Exclusive club for India’s top techies",
    subHeading: "Our candidates are handpicked after thorough profile checks",
    redirectionUrl:`${process.env.NEXT_PUBLIC_JOBSEEKER_BASE_URL}/software-jobs?utm_source=techminis&utm_campaign=techminis_`

  },
  {
    height: 131,
    width: 132,
    imgSrc: CounsellorImage,
    heading: "Dedicated Talent Advisor for assistance",
    subHeading: "Get thorough assistance till you land your dream job",
    redirectionUrl:`${process.env.NEXT_PUBLIC_JOBSEEKER_BASE_URL}?utm_source=techminis&utm_campaign=techminis_`

  }
];
