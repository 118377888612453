import { trackCHEvents } from "@analytics/utils/trackEvents";
import { compact } from "@utils/fn-utils";
import { getPageName } from "@utils/getPageName";

export const trackDiscoverMorePage = () => {
  trackCHEvents(`discovermorewithtags_page_load`);
};

export const trackCTAs = ({ element_name, type = "", element_type = "link" }) => {
  const page = type === "drawer" ? "discovermorewithtags" : getPageName();
  const payload = compact({
    element_name,
    element_type,
    action_type: "click",
    page_section: "discovermorewithtags_bellywidget"
  });

  trackCHEvents(
    type
      ? `${page}_page_${element_name}_click`
      : `${page}_page_discovermorewithtags_bellywidget_${element_name}_click`,
    payload
  );
};

export const trackTagChickletClick = ({ element_name, data, type = "" }) => {
  const payload = compact({
    element_name,
    element_type: "chicklet",
    action_type: "click",
    page_section: "discovermorewithtags_bellywidget",
    ...data
  });
  const page = type === "drawer" ? "discovermorewithtags" : getPageName();

  trackCHEvents(
    type
      ? `${page}_page_${element_name}_click`
      : `${page}_page_discovermorewithtags_bellywidget_${element_name}_click`,
    payload
  );
};
