import { trackCHEvents } from "@analytics/utils/trackEvents";
import { compact } from "@utils/fn-utils";
import { getPageName } from "@utils/getPageName";

export const trackCTAs = ({ element_name, page_section = "bigshyft_banner", articleId = undefined }) => {
  const page = getPageName();

  const data = articleId ? { article_id: articleId } : undefined;

  const payload = compact({
    element_name,
    element_type: "button",
    action_type: "click",
    page_section: page_section,
    screenName:  page,
    ...data
  });

  trackCHEvents(`${page}_page_${element_name}_click`, payload);
};
