import React, {ReactNode} from "react";

type ClickOutsideProps = {
  handleClickOutside: Function,
  children: ReactNode,
  disable?: Boolean
}

class ClickOutside extends React.PureComponent<ClickOutsideProps> {
  domNode: any;
  constructor(props: ClickOutsideProps | Readonly<ClickOutsideProps>) {
    super(props);
    this.domNode = null;
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = e => {
    if (
      !this.props.disable &&
      this.domNode &&
      !this.domNode.contains(e.target)
    ) {
      this.props.handleClickOutside(e);
    }
  };

  disablePropagation = e => {
    e.stopPropagation();
  };

  render() {
    return (
      <div
        onClick={this.disablePropagation}
        ref={c => {
          this.domNode = c;
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ClickOutside;
