import urls from "@apiServices/apiUrls";
import http from "@apiServices/http";
import { getCookies } from "cookies-next";
// import { nanoid } from "nanoid";
// import { doc, getDoc, getFirestore } from "firebase/firestore/lite";

const getUserPreferences = async ({ userData }) => {
  const selectedTags = [];
  // const queryParams = query;
  const queryTagIds = [] // TagIds to be ignored - discussed with aman
  //  queryParams.tagIds ? queryParams.tagIds.split(",") : [];
  const cookies = getCookies();
  let userId = cookies["techminis_u_id"]
  let followingPreferences = [];
  let savedKeywords = [];
  let suggestedPreferences = [];

  let preferenceSelected = [];
  // let docRef;
  // const firestore = new firestoreService();
  // let userId = cookies["id"];
  // let userData = await firestore.fetchUserData(userId);
  // const db = getFirestore();

  if (userId) {
    // docRef = doc(db, "users", userId);
    // const docSnap = userId ? await getDoc(docRef) : null;

    // if (docSnap?.exists() && docSnap.data()) {
    //   userData = docSnap.data();
    preferenceSelected = userData?.preferences || [];
    // selectedPreferences = userData?.preferences || [];
    savedKeywords = userData?.savedKeywords || [];
    // }
  }

  await http
    .get(urls.suggestions, {
      headers: {
        uid: userId
      }
    })
    .then(async (data: any) => {
      data.map((item, index) => {
        if (index === 0) {
          item["isSelected"] = true;
        } else {
          item["isSelected"] = false;
        }

        item.tags.map((el) => {
          el["isSelected"] = false;
          el["channelName"] = item.channelName;
        });
      });
      //   prefs = JSON.stringify(data);

      data.map((item) => {
        const size = 5;
        const temp = [];
        item.tags.map((tag) => {
          if (queryTagIds.includes(tag.id)) {
            tag.isSelected = true;
            selectedTags.push(tag);
          } else {
            if (!preferenceSelected.includes(tag.id)) {
              temp.push(tag);
            } else {
              tag.isSelected = true;

              selectedTags.push(tag);
            }
            // tag["type"] = "preference";
          }
        });
        // selected tagIds to be updated in firestore

        const res = temp.reduce((acc, curr, i) => {
          if (!(i % size)) {
            // if index is 0 or can be divided by the `size`...
            acc.push(temp.slice(i, i + size)); // ..push a chunk of the original array to the accumulator
          }
          return acc;
        }, []);
        item["tagDetail"] = res;

        item["tagDetail"].map((el) => {
          el.map((tag) => {
            if (preferenceSelected.includes(tag.id)) {
              tag["isSelected"] = true;
            } else {
              tag["isSelected"] = false;
            }
          });
        });
      });
      const dataTemp = [];
      data = data.map((el) => {
        if (el.tagDetail.length > 0) {
          dataTemp.push(el);
        }
      });

      suggestedPreferences = dataTemp;
      followingPreferences = selectedTags;
    })
    .catch(() => { });

  return {
    followingPreferencesData: followingPreferences, //followingPreferences
    savedKeywordsData: savedKeywords, //savedKeywords,
    suggestedPreferencesData: suggestedPreferences
  };
};

export default getUserPreferences;
