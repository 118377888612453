export const getBookmarkedArticles = ({ userFirestoreData }) => {
  if (!userFirestoreData?.bookmarks) {
    return [];
  }
  let list = [];
  for (let i = 0; i < userFirestoreData?.bookmarks.length; i += 1) {
    if (!!userFirestoreData?.bookmarks[i].articleIds) {
      list = [...list, ...userFirestoreData?.bookmarks[i]?.articleIds];
    }
  }
  return list;
};

export const getUserBookmarkGroups = ({ userFirestoreData }) => {
  let GROUP_LIST = [];
  userFirestoreData?.bookmarks &&
    userFirestoreData?.bookmarks.map((item) => {
      GROUP_LIST.push(item.groupName);
    });
  return GROUP_LIST;
};
